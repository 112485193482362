import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import Stop from '../../assets/STOP-white.png';
import './AccessDenied.scss';

// Define background change
const bodyStyle = `body { background-image: url('/public-images/not-found.jpeg'); transition: all 1s ease-in-out; }`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function AccessDenied(props) {
    const { sideBarOpen } = props;
    const [pageName, setPageName] = useState('this page.');
    const urlParams = new URLSearchParams(window.location.search);
    const reason = urlParams.get('reason');
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    useEffect(() => {
        if (reason === 'no-admin-access') {
            setPageName('the Admin Dashboard.');
        }
    }, [reason])

    return (
        <section id="access-denied-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Page Not Found</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="not-fount-wrapper-div" className="access-denied-wrapper-div">
                <div id="access-denied-content" className="access-denied-content">
                    <div>
                        <img src={Stop} alt='STOP.png' />
                    </div>
                    <h1>403: Access Denied</h1>
                    <p>Sorry! You don't have permission to access {pageName}</p>
                    <p id="sys-admin">Please contact your system administrator.</p>
                </div>
            </div>
        </section>
    );
}

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './Schedule.scss';

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/schedule.jpeg'); 
        background-position: bottom; 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

// Page to view project details
export function Schedule(props) {
    // Get props
    const { sideBarOpen } = props;
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    return (
        <section id="project-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Schedule</title>
                <style>{bodyStyle}</style>
            </Helmet>

            <div id="project-title-div" className="project-title">
                <h1>#ComeCtheSound</h1>
                <h3>Find out when and where Chucktown Sound will perform next!</h3>
            </div>

            <div id="project-wrapper-div" className="project-wrapper-div">
                <div id="project-content" className="project-content">
                    <iframe 
                        src="https://calendar.google.com/calendar/embed?src=0nm1942jgva324vb1b7n7a0vpo%40group.calendar.google.com&ctz=America%2FNew_York" 
                        title='CTS Schedule' 
                        style={{border: "0",padding: '0.5em'}} 
                        width="95%" 
                        height="95%" 
                        border="0" 
                        overflow="hidden"
                    />
                </div>
            </div>
        </section>
    );
};

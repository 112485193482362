import React, { useEffect, useState } from 'react';
import { MdClose, MdOutlineScreenRotation } from 'react-icons/md';
import { Oval } from 'react-loading-icons';
import './Modals.scss';
import './AttendanceCodeModal.scss';

export function AttendanceCodeModal(props) {
    // Get props and initalize state variables
    const { closeHandler, code } = props;
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    const handleOrientationChange = (event) => {
        setIsPortrait(event.matches);
    };

    useEffect(() => {
        const orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        orientationMediaQuery.addEventListener('change', handleOrientationChange);

        // Initial check
        setIsPortrait(orientationMediaQuery.matches);

        return () => {
            orientationMediaQuery.removeEventListener('change', handleOrientationChange);
        };
    }, []);
    
    const handleClose = (action) => {
        closeHandler(action);
    };

    return (
        <>
            <div id="attendance-code-modal" className="attendance-code-modal">
                <div className="attendance-code-modal-content">
                    <div className={`modal-close ${isPortrait ? 'small' : 'large'}`} onClick={handleClose}>
                        <MdClose />
                    </div>
                    {isPortrait && (
                        <div>
                            <div>
                                <h2>PLEASE ROTATE YOUR DEVICE</h2>
                            </div>
                            <div style={{paddingTop: 15}}>
                                <MdOutlineScreenRotation style={{fontSize: 150}} />
                            </div>
                        </div>
                    )}

                    {!isPortrait && (
                        <h1 className="large">{code === 123456 ? <Oval className="loading-code-large" /> : code}</h1>
                    )}
                </div>
            </div>
        </>
    );
};
import React, { 
    useState, 
    useEffect,
    useCallback, 
    ChangeEvent,
    MouseEvent,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { assignToCurrentRoster, getAllUnassignedUsers, getCurrentRoster, unassignFromCurrentRoster } from '../../api/firebaseApi';
import { Oval } from 'react-loading-icons';
import { FaSearch } from 'react-icons/fa';
import { FcInfo } from 'react-icons/fc';
import { 
    Chip, 
    TextField, 
    InputAdornment, 
    InputLabel, 
    Select, 
    MenuItem, 
    SelectChangeEvent, 
    Checkbox
} from '@mui/material';
import { DataTablePagination } from '../TablePagination/DataTablePagination';

// Roster types
type MainRoster = {
    Email: string;
    FirstName: string;
    LastName: string;
    Uuid: string;
};

// Roster list types
type MainRostersList = Array<MainRoster>;

// Search text types
type SearchTexts = {
    searchFull: string;
    searchCurrent: string;
};

// Search filter option types
type FilterOptions = {
    searchOptionFull: string;
    searchOptionCurRost: string;
};

// Transfer selections types
type TransferSelections = {
    addSelections: MainRostersList;
    removeSelections: Array<string>;
}

// Alert payload types
type AlertMessage = {
    id: string;
    type: string;
    message: string;
};

// Account type
type Account = {
    length: number;
    Active: boolean;
    Admin: {
      AdminRequests: boolean;
      Announcements: boolean;
      AttendanceForms: boolean;
      Awards: boolean;
      FoodOrders: boolean;
      Members: boolean;
      PhysicalInventory: boolean;
      Users: boolean;
    },
    Alum: boolean,
    BirthDay: number,
    BirthMonth: number,
    CreatedOn: string,
    FirstName: string,
    GradSemester: string,
    GradYear: number,
    Instrument: Array<string>,
    LastName: string,
    Rookie: boolean,
    Staff: boolean,
    Student: string | boolean,
    Username: string,
    Verified: boolean,
    Year: string,
    OtherYear: string,
    Email: string
};

// Full roster response types
type FullRosterResponse = Array<boolean | Account | string | null>;

export function ManageRosters(props: { alert: Function }) {
    const { alert } = props;
    const [storedFullRoster, setStoredFullRoster] = useState<MainRostersList>([{
        Email: '',
        FirstName: '',
        LastName: '',
        Uuid: '',
    }]);
    const [fullRoster, setFullRoster] = useState<MainRostersList>([{
        Email: '',
        FirstName: '',
        LastName: '',
        Uuid: '',
    }]);
    const [storedCurrentRoster, setStoredCurrentRoster] = useState<MainRostersList>([{
        Email: '',
        FirstName: '',
        LastName: '',
        Uuid: '',
    }]);
    const [currentRoster, setCurrentRoster] = useState<MainRostersList>([{
        Email: '',
        FirstName: '',
        LastName: '',
        Uuid: '',
    }]);
    const [searchTexts, setSearchTexts] = useState<SearchTexts>({
        searchFull: '',
        searchCurrent: '',
    });
    const [transferSelections, setTransferSelections] = useState<TransferSelections>({
        addSelections: [],
        removeSelections: [],
    });
    const [filterOptions, setFilterOptions] = useState<FilterOptions>({ searchOptionFull: 'LastName', searchOptionCurRost: 'LastName' });
    const [fullRosterLength, setFullRosterLength] = useState<number>(0);
    const [currRosterLength, setCurrRosterLength] = useState<number>(0);
    const [isLoadingFullRoster, setIsLoadingFullRoster] = useState<boolean>(false);
    const [isLoadingCurrRoster, setIsLoadingCurrRoster] = useState<boolean>(false);
    const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState<boolean>(false);
    const [isUserToAdd, setIsUserToAdd] = useState<boolean>(false);
    const [isUserToRemove, setIsUserToRemove] = useState<boolean>(false);
    const [rowsPerPageFull, setRowsPerPageFull] = useState<number>(5);
    const [rowsPerPageCurr, setRowsPerPageCurr] = useState<number>(5);
    const [pageFull, setPageFull] = useState<number>(0);
    const [pageCurr, setPageCurr] = useState<number>(0);
    const [isSelected1, setIsSelected1] = useState<boolean>(false);
    const [isSelected2, setIsSelected2] = useState<boolean>(false);
    const startIndexFull = pageFull * rowsPerPageFull;
    const startIndexCurr = pageCurr * rowsPerPageCurr;
    const endIndexFull = startIndexFull + rowsPerPageFull;
    const endIndexCurr = startIndexCurr + rowsPerPageCurr;

    // Determines number of empty data rows
    const emptyRowsFull = pageFull >= 0 
        ? Math.max(0, (1 + pageFull) * rowsPerPageFull - fullRosterLength) 
        : 0
    ;

    const emptyRowsCurr = pageCurr  >= 0 
    ? Math.max(0, (1 + pageCurr ) * rowsPerPageCurr  - currRosterLength) 
    : 0
;

    // Table pagination page change handler
    const handlePageChangeFull = (event: ChangeEvent<HTMLSelectElement>, newPage: number) => {
        event.preventDefault();
        setPageFull(newPage);
    };

    const handlePageChangeCurr = (event: ChangeEvent<HTMLSelectElement>, newPage: number) => {
        event.preventDefault();
        setPageCurr(newPage);
    };

    // Table pagination rows per page change handler
    const handleRowsPerPageChangeFull = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setRowsPerPageFull(parseInt(value, 10));
        setPageFull(0);
    };

    const handleRowsPerPageChangeCurr = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setRowsPerPageCurr(parseInt(value, 10));
        setPageCurr(0);
    };

    // Alert handler function
    const alertCallbackHandler = useCallback((payload: AlertMessage) => {
        alert(payload);
    }, [alert]);

    const alertHandler = (payload: AlertMessage | Array<AlertMessage>) => {
        alert(payload);
    };

    // Function to sort data
    const sortData = (sortBy: string, data: any) => {
        return data.sort((a:MainRoster, b:MainRoster) => a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? -1 : 1);
    };

    // Function to handle search boxes
    const handleSearchFull = () => {
        const data = [...storedFullRoster];
        const option = filterOptions.searchOptionFull;
        const filteredData = data.filter((row) => row[`${option}`]
            .toLowerCase()
            .includes(searchTexts.searchFull)
        );
        if (filteredData.length === 0) {
            setFullRoster([]);
            setFullRosterLength(1);
        } else {
            const sortedData = sortData(option, filteredData)
            setFullRoster(sortedData);
            setFullRosterLength(sortedData.length);
        }
        setPageFull(0);
    };

    const handleSearchCurr = () => {
        const data = [...storedCurrentRoster];
        const option = filterOptions.searchOptionCurRost;
        const filteredData = data.filter((row) => row[`${option}`]
            .toLowerCase()
            .includes(searchTexts.searchCurrent)
        );
        if (filteredData.length === 0) {
            setCurrentRoster([]);
            setCurrRosterLength(1);
        } else {
            const sortedData = sortData(option, filteredData)
            setCurrentRoster(sortedData);
            setCurrRosterLength(sortedData.length);
        }
        setPageCurr(0);
    };

    useEffect(() => {
        const fetch = async () => {
            setIsLoadingFullRoster(true);
            const option = filterOptions.searchOptionFull;
            const roster:FullRosterResponse = await getAllUnassignedUsers(option);
            if (roster[0] && roster[1] && typeof roster[1] !== 'string' && typeof roster[1] !== 'boolean') {
                const data = roster[1];
                const rosterData:any = [];
                for (let i = 0; i < data.length; i++) {
                    rosterData.push({
                        Email: data[i].Email,
                        FirstName: data[i].FirstName,
                        LastName: data[i].LastName,
                        Uuid: uuidv4(),
                    });
                }
                setStoredFullRoster(rosterData);
                setFullRoster(rosterData);
                setFullRosterLength(rosterData.length);
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: `${roster[1]}`,
                }
                alertCallbackHandler(payload);
            }
            setIsLoadingFullRoster(false);
        }
        if (fullRosterLength === 0 ) {
            fetch();
        }
    }, [alertCallbackHandler, fullRosterLength, filterOptions.searchOptionFull]);

    useEffect(() => {
        const fetch = async () => {
            setIsLoadingCurrRoster(true);
            const roster:FullRosterResponse = await getCurrentRoster();
            if (roster[0] && roster[1] && typeof roster[1] !== 'string' && typeof roster[1] !== 'boolean') {
                const data = roster[1];
                const rosterData:any = [];
                for (let i = 0; i < data.length; i++) {
                    rosterData.push({
                        Email: data[i].data.Email,
                        FirstName: data[i].data.FirstName,
                        LastName: data[i].data.LastName,
                        Uuid: data[i].key,
                    });
                }
                setStoredCurrentRoster(rosterData);
                setCurrentRoster(rosterData);
                setCurrRosterLength(rosterData.length);
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: `${roster[1]}`,
                }
                alertCallbackHandler(payload);
            }
            setIsLoadingCurrRoster(false);
        }
        if (currRosterLength === 0 ) {
            fetch();
        }
    }, [alertCallbackHandler, currRosterLength]);

    const updateSearchControl = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target;
        const updatedForm = { ...searchTexts };
        updatedForm[id] = value.toLowerCase();
        setSearchTexts(updatedForm);
    };

    const updateFilterOptions = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        const updatedFilterForm = { ...filterOptions };
        updatedFilterForm[name] = value;
        setFilterOptions(updatedFilterForm);
    };

    const handleCheckboxControl = (event: MouseEvent<HTMLButtonElement, MouseEvent>, data: MainRoster | string, action: string) => {
        const target = event.target as HTMLInputElement;
        const { checked } = target;
        const updateSelections= {...transferSelections};
        if (checked && action === 'add' && typeof data === 'object') {
            const updateArray = [...updateSelections.addSelections];
            updateArray.push(data);
            updateSelections.addSelections = updateArray;
        } else if (checked && action === 'remove' && typeof data === 'string') {
            const updateArray = [...updateSelections.removeSelections];
            updateArray.push(data);
            updateSelections.removeSelections = updateArray;
        } else if (!checked && action === 'add' && typeof data === 'object') {
            const updateArray = [...updateSelections.addSelections];
            for (let i = 0; i < updateArray.length; i++) {
                if (updateArray[i].Uuid === data.Uuid) {
                    updateArray.splice(i, 1);
                }
            }
            updateSelections.addSelections = updateArray;
        } else if (!checked && action === 'remove' && typeof data === 'string') {
            const updateArray = [...updateSelections.removeSelections];
            for (let i = 0; i < updateArray.length; i++) {
                if (updateArray[i] === data) {
                    updateArray.splice(i, 1);
                }
            }
            updateSelections.removeSelections = updateArray;
        }
        setTransferSelections(updateSelections);
    };

    const addToCurrentRoster = async () => {
        setIsLoadingAdd(true);
        setIsUserToAdd(false);
        const res = await assignToCurrentRoster(transferSelections.addSelections);
        let payload:AlertMessage;
        if (res[0]) {
            payload = {
                id: uuidv4(),
                type: 'success',
                message: 'User(s) added to Current Roster sucessfully!'
            };
            refreshCurrentRosterTables();
            const prev = { ...transferSelections }
            prev.addSelections = [];
            setTransferSelections(prev);
        } else {
            payload = {
                id: uuidv4(),
                type: 'error',
                message: `${res[1]}`,
            };
        }
        setIsLoadingAdd(false);
        alertHandler(payload);
    };

    const removeFromCurrentRoster = async () => {
        setIsLoadingRemove(true);
        setIsUserToRemove(false);
        const res = await unassignFromCurrentRoster(transferSelections.removeSelections);
        let payload:AlertMessage;
        if (res[0]) {
            payload = {
                id: uuidv4(),
                type: 'success',
                message: 'User(s) unadded from Current Roster successfully!',
            };
            refreshCurrentRosterTables();
            const prev = { ...transferSelections };
            prev.removeSelections = [];
            setTransferSelections(prev);
        } else {
            payload = {
                id: uuidv4(),
                type: 'error',
                message: `${res[1]}`,
            };
        }
        alertHandler(payload);
        setIsLoadingRemove(false);
    };
    

    useEffect(() => {
        if (transferSelections.addSelections.length > 0) {
            setIsUserToAdd(true);
        } else {
            setIsUserToAdd(false);
        }
        
        if (transferSelections.removeSelections.length > 0) {
            setIsUserToRemove(true);
        } else {
            setIsUserToRemove(false);
        }
    }, [transferSelections.removeSelections.length, transferSelections.addSelections.length]);

    const refreshCurrentRosterTables = () => {
        setFullRosterLength(0);
        setCurrRosterLength(0);
    };

    const adornament = {
        startAdornment: (
            <InputAdornment position="start">
                <FaSearch style={{fontSize: "110%"}} className="snow" />
            </InputAdornment>
        )
    };

    const searchAdornmentFull = isSelected1 ? adornament : {};
    const searchAdornmentCurr = isSelected2 ? adornament : {};

    return (
        <>
            <div id="current-roster-div">
                <h1>Current Roster</h1>
                <div className="chip-div">
                    <Chip 
                        className="MuiChip-root info white"
                        icon={<FcInfo style={{transform: 'translateY(-1px)'}} />} 
                        label={
                            <h4>
                                Select a user to unadd from the current roster
                            </h4>
                        } 
                        variant="outlined" 
                    />
                </div>
                <div className="search-div">
                    <TextField 
                        id="searchCurrent" 
                        label="Search"
                        variant="outlined"
                        type="text"
                        onChange={updateSearchControl}
                        value={searchTexts.searchCurrent}
                        InputProps={searchAdornmentCurr}
                        onFocus={e => setIsSelected2(true)}
                        onBlur={searchTexts.searchCurrent ? undefined : e => setIsSelected2(false)}
                        onInput={e => setIsSelected2(true)}
                        className="search-box"
                    />
                    <div className="select-div">
                        <InputLabel id="full-filter">Filter By</InputLabel>
                        <Select
                            labelId="full-filter"
                            id="searchOptionCurRost"
                            name="searchOptionCurRost"
                            variant="outlined"
                            value={filterOptions.searchOptionCurRost}
                            label="Filter By"
                            onChange={updateFilterOptions}
                            className="MuiSelect-outlined MuiSelect-outlined search-select"
                        >
                            <MenuItem value="LastName">Last Name</MenuItem>
                            <MenuItem value="FirstName">First Name</MenuItem>
                        </Select>
                    </div>
                    <button className="main-button" onClick={handleSearchCurr}>Search</button>
                </div>
                <div className="data-table-div">
                    <table>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currRosterLength > 0 && (rowsPerPageCurr > 0
                                    ? currentRoster.slice(startIndexCurr, endIndexCurr)
                                    : currentRoster
                                ).map((row) => {
                                    return (
                                        <tr key={row.Uuid}>
                                            <td>
                                                <Checkbox 
                                                    sx={{padding: 0}} 
                                                    onClick={(e) => handleCheckboxControl(e, row.Uuid, 'remove')}
                                                    className="main-checkbox"
                                                />
                                            </td>
                                            <td>{row.LastName}</td>
                                            <td>{row.FirstName}</td>
                                        </tr>
                                    )
                                })
                            }

                            {(currRosterLength !== 0 && emptyRowsCurr > 0) && (
                                <tr style={{ height: 25.5 * emptyRowsCurr }}>
                                    <td colSpan={6} aria-hidden />
                                </tr>
                            )}
                            {currRosterLength === 0 && (
                                <tr>                                
                                    {isLoadingCurrRoster 
                                        ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                        : <td colSpan={6}>
                                            <Chip 
                                                className="MuiChip-root MuiChip-root info-table"
                                                icon={<FcInfo />} 
                                                label="No Users to Display" 
                                                variant="outlined" 
                                            />
                                        </td>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {currRosterLength > 0 && (
                        <DataTablePagination
                            length={currRosterLength}
                            rowsPerPage={rowsPerPageCurr}
                            page={pageCurr}
                            handlePageChange={handlePageChangeCurr}
                            handleRowsPerPageChange={handleRowsPerPageChangeCurr}
                        />
                    )}
                    <div className="roster-action-button">
                        {isLoadingRemove && <Oval />}
                        <button 
                            disabled={!isUserToRemove} 
                            className="main-button"
                            onClick={removeFromCurrentRoster}
                        >
                            {isLoadingRemove ? 'Removing...' : 'Remove Selected Names'}
                        </button>
                    </div>
                </div>
                <h1>Unassigned Users</h1>
                <div className="chip-div">
                    <Chip 
                        className="MuiChip-root info white"
                        icon={<FcInfo style={{transform: 'translateY(-1px)'}} />} 
                        label={
                            <h4>
                                Select a user to add to the current roster
                            </h4>
                        } 
                        variant="outlined" 
                    />
                </div>
                <div className="search-div">
                    <TextField 
                        id="searchFull" 
                        label="Search"
                        variant="outlined"
                        type="text"
                        onChange={updateSearchControl}
                        value={searchTexts.searchFull}
                        InputProps={searchAdornmentFull}
                        onFocus={e => setIsSelected1(true)}
                        onBlur={searchTexts.searchFull ? undefined : e => setIsSelected1(false)}
                        onInput={e => setIsSelected1(true)}
                        className="search-box"
                    />
                    <div className="select-div">
                        <InputLabel id="full-filter">Filter By</InputLabel>
                        <Select
                            labelId="full-filter"
                            id="searchOptionFull"
                            name="searchOptionFull"
                            variant="outlined"
                            value={filterOptions.searchOptionFull}
                            label="Filter By"
                            onChange={updateFilterOptions}
                            className="MuiSelect-outlined MuiSelect-outlined search-select"
                        >
                            <MenuItem value="LastName">Last Name</MenuItem>
                            <MenuItem value="FirstName">First Name</MenuItem>
                        </Select>
                    </div>
                    <button className="main-button" onClick={handleSearchFull}>Search</button>
                </div>
                <div className="data-table-div">
                    <table>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fullRosterLength > 0 && (rowsPerPageFull > 0
                                    ? fullRoster.slice(startIndexFull, endIndexFull)
                                    : fullRoster
                                ).map((row) => {
                                    const selectionData = {
                                        Email: row.Email,
                                        LastName: row.LastName,
                                        FirstName: row.FirstName,
                                        Uuid: row.Uuid,
                                    };
                                    return (
                                        <tr key={row.Uuid}>
                                            <td>
                                                <Checkbox 
                                                    sx={{padding: 0}} 
                                                    onClick={(e) => handleCheckboxControl(e, selectionData, 'add')}
                                                    className="main-checkbox"
                                                />
                                            </td>
                                            <td>{row.LastName}</td>
                                            <td>{row.FirstName}</td>
                                        </tr>
                                    )
                                })
                            }

                            {(fullRosterLength !== 0 && emptyRowsFull > 0) && (
                                <tr style={{ height: 25.5 * emptyRowsFull }}>
                                    <td colSpan={6} aria-hidden />
                                </tr>
                            )}
                            {fullRosterLength === 0 && (
                                <tr>                                
                                    {isLoadingFullRoster 
                                        ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                        : <td colSpan={6}>
                                            <Chip 
                                                className="MuiChip-root MuiChip-root info-table"
                                                icon={<FcInfo />} 
                                                label="No Users to Display" 
                                                variant="outlined" 
                                            />
                                        </td>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {fullRosterLength > 0 && (
                        <DataTablePagination
                            length={fullRosterLength}
                            rowsPerPage={rowsPerPageFull}
                            page={pageFull}
                            handlePageChange={handlePageChangeFull}
                            handleRowsPerPageChange={handleRowsPerPageChangeFull}
                        />
                    )}
                    <div className="roster-action-button">
                        {isLoadingAdd && <Oval />}
                        <button 
                            disabled={!isUserToAdd} 
                            className="main-button"
                            onClick={addToCurrentRoster}
                        >
                            {isLoadingAdd ? 'Adding...' : 'Add Selected Names'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

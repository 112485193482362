import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AiOutlineMail } from 'react-icons/ai';
import { SiInstagram, SiX} from 'react-icons/si';
import { FaSquareFacebook } from 'react-icons/fa6';
import { ImageSlideshow } from '../../components/ImageSlideshow/ImageSlideshow';
import logo from '../../assets/main-icon.png';
import './Home.scss';
import { IconContext } from 'react-icons/lib';

// Define page background
const bodyStyle = `
    body { 
        background-image: url('/public-images/home.jpg'); 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

const SecretLink = () => <a className="hidden-link" href="https://drive.google.com/file/d/1ieoPMW1q3tePUt5dJix0YWT-7ECLouiZ/view?usp=sharing" target="_blank" rel="noreferrer">O</a>;

export function Home(props) {
    // Get props and set state variables
    const { sideBarOpen } = props;
    const [showBullets, setShowBullets] = useState(true);
    const [showSecretLink, setShowSecretLink] = useState(false);
    const [screenSize, setScreenSize] = useState(screens);
    const [slideWidth, setSlideWidth] = useState(650);
    const [slideHeight, setSlideHeight] = useState(400);
    const [slideWidthOpen, setSlideWidthOpen] = useState(550);
    const [slideHeightOpen, setSlideHeightOpen] = useState(300);
    const [logoWidth, setLogoWidth] = useState(350);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    useEffect(() => {
        if (screenSize.small) {
            setSlideWidth(300);
            setSlideHeight(200);
            setSlideWidthOpen(300);
            setSlideHeightOpen(200);
            setLogoWidth(200);
            setShowBullets(false);
            setShowSecretLink(false);
        } else if (screenSize.tabletPort) {
            setSlideWidth(600);
            setSlideHeight(350);
            setSlideWidthOpen(500);
            setSlideHeightOpen(300);
            setLogoWidth(300);
            setShowBullets(true);
            setShowSecretLink(false);
        } else if (screenSize.tabletLand) {
            setSlideWidth(600);
            setSlideHeight(400);
            setSlideWidthOpen(500);
            setSlideHeightOpen(300);
            setLogoWidth(350);
            setShowBullets(true);
            setShowSecretLink(false);
        } else if (screenSize.large) {
            setSlideWidth(750);
            setSlideHeight(500);
            setSlideWidthOpen(650);
            setSlideHeightOpen(400);
            setLogoWidth(400);
            setShowBullets(true);
            setShowSecretLink(false);
        } else if (screenSize.xlarge) {
            setSlideWidth(1100);
            setSlideHeight(700);
            setSlideWidthOpen(1000);
            setSlideHeightOpen(600);
            setLogoWidth(500);
            setShowBullets(true);
            setShowSecretLink(false);
        } else {
            setSlideWidth(650);
            setSlideHeight(400);
            setSlideWidthOpen(550);
            setSlideHeightOpen(300);
            setLogoWidth(350);
            setShowBullets(true);
            setShowSecretLink(true);
        }
    }, [screenSize]);

    return (
        <section id="home-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Home</title>
                <style>{bodyStyle}</style>
            </Helmet>

            <div id="home-wrapper-div" className="home-wrapper-div">
                <div id="home-content" className="home-content">
                    <div id="home-content-left">
                        <img src={logo} alt='logo.png' style={(sideBarOpen || screenSize.small) ? {width: logoWidth} : {}} />
                        <h1>
                            College of Charleston's&nbsp;
                            {showSecretLink 
                                ? <SecretLink />
                                : 'O'
                            }
                            fficial Athletic Band
                        </h1>
                        {(!screenSize.tabletPort && !screenSize.small) && (
                            <>
                                <Link to="/auditions">
                                    <button className="main-button">Audition</button>
                                </Link>
                                <Link to="/contact">
                                    <button className="main-button">Contact Us</button>
                                </Link>   
                            </>
                        )}
                        {screenSize.small && (
                            <>
                                <Link to="/auditions">
                                    <button className="main-button">Audition</button>
                                </Link>
                                <Link to="/contact">
                                    <button className="main-button">Contact Us</button>
                                </Link>  
                                <Link to="/about">
                                    <button className="main-button">Learn More</button>
                                </Link> 
                            </>
                        )}
                    </div>
                    <div id="home-content-right" className={sideBarOpen ? 'open' : ''}>
                        <ImageSlideshow 
                            path={'Home'} 
                            width={sideBarOpen ? slideWidthOpen : slideWidth}
                            height={sideBarOpen ? slideHeightOpen : slideHeight}
                            bullets={showBullets}
                        />
                        {!screenSize.small && (
                            <Link to="/about">
                                <button className="main-button">Learn More</button>
                            </Link>
                        )}
                        {screenSize.tabletPort && (
                            <>
                                <Link to="/auditions">
                                    <button className="main-button">Audition</button>
                                </Link>
                                <Link to="/contact">
                                    <button className="main-button">Contact Us</button>
                                </Link>
                            </>
                        )}
                        <IconContext.Provider value={{className: 'link-icons'}}>
                            <h2 id="tags">#ComeCtheSound &nbsp;
                                <a href="https://www.instagram.com/chucktown_sound" target="_blank" rel="noreferrer">
                                    <SiInstagram />
                                </a> &nbsp;
                                <a href="https://www.facebook.com/cofc.chucktownsound" target="_blank" rel="noreferrer">
                                    <FaSquareFacebook />
                                </a> &nbsp;
                                <a href="https://twitter.com/chucktown_sound" target="_blank" rel="noreferrer">
                                    <SiX />
                                </a> &nbsp;
                                <Link to="/contact">
                                    <AiOutlineMail />
                                </Link>
                            </h2>
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        </section>
    );
};

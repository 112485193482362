import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { IoIosMail } from 'react-icons/io';
import { GoChecklist } from 'react-icons/go';
import { FaUsersCog } from 'react-icons/fa';
import './AdminToolTabs.scss';

// Define screen sizes
const screens = {
  small: window.matchMedia("all and (max-device-width: 640px)").matches,
  tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
  tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
  medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
  large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
  xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function AdminToolTabs(props) {
  const { children, value, handleChange } = props;
  const [screenSize, setScreenSize] = useState(screens);

  useEffect(() => {
      const screenSizes = {
          small: window.matchMedia("all and (max-device-width: 640px)").matches,
          tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
          tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
          medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
          large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
          xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
      };
      const keys = Object.keys(screenSizes);

      const prev = {...screenSizes}
      const prevKeys = Object.keys(prev);
      for (let i = 0; i < prevKeys.length; i++) {
          if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
              prev[prevKeys[i]] = screenSizes[keys[i]];
          }
      }
      setScreenSize(prev);
  }, []);

  return (
    <Box>
      <Box className="tab-nav">
        <Tabs 
          value={value} 
          onChange={handleChange}
          variant="scrollable"
        >
          <Tab 
              selectionFollowsFocus 
              icon={screenSize.small ? '' : <GoChecklist className="tab-icon" />} 
              label="Manage Rosters" 
          />
          <Tab 
              selectionFollowsFocus 
              icon={screenSize.small ? '' : <IoIosMail className="tab-icon" />} 
              label="Email Verifications" 
          />
          <Tab 
              selectionFollowsFocus 
              icon={screenSize.small ? '' : <FaUsersCog className="tab-icon" />} 
              label="Manage Users" 
          />
        </Tabs>
      </Box>
      {children}
    </Box>
  );
}

import React from 'react';
import Box from '@mui/material/Box';

export function AdminToolTabPanels(props) {
  const { children, value, index } = props;

  return (
    <div role='tabpanel' id={`simple-tabpanel-${index}`}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../assets/main-icon.png';
import logo2 from '../../assets/ctwnsnd.png';
import insta from '../../assets/insta.png';
import face from '../../assets/face.png';
import x from '../../assets/x.png';
import { ContactForm } from '../../components/Forms/ContactFrom.tsx'
import './Contact.scss';

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/contact.jpg'); 
        background-position: bottom; 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

// Page to display contact info
export function Contact(props) {
    // Get the component props
    const { sideBarOpen, alert } = props;
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSize}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, [screenSize]);

    // Function to show a message from audition form
    const showAlert = (alertPayload) => {
        alert(alertPayload);
    }

    return (
        <section id="contact-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Contact Us</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="contact-title-div" className="contact-title">
                <div id="contact-title-left">
                    <img src={logo} alt="logo.png" />
                </div>
                <div id="contact-title-right">
                    <h1>Got Questions?</h1>
                    <h3>We Want to Hear Them!</h3>
                </div>
            </div>
            <div id="contact-wrapper-div" className="contact-wrapper-div">
                <div id="contact-content" className="contact-content">
                    {!screenSize.small && (
                        <div className="contact-split-left">
                            <ContactForm alert={showAlert} />
                        </div>
                    )}
                    <div className="contact-split-right">
                        <img src={logo2} alt='ctwnsnd.png' className="logo" />
                        <h2>Add Our Official Social Media</h2>
                        <div>
                            <a href='https://www.instagram.com/chucktown_sound/' target="_blank" rel="noreferrer">
                                <img src={insta} alt='insta.png' className="social-icon" />
                            </a>
                            <a href='https://www.facebook.com/cofc.chucktownsound' target="_blank" rel="noreferrer">
                                <img src={face} alt='face.png' className="social-icon" />
                            </a>
                            <a href='https://x.com/chucktown_sound' target="_blank" rel="noreferrer">
                                <img src={x} alt='twit.png' className="social-icon" />
                            </a>
                        </div>
                    </div>
                    {screenSize.small && (
                        <div className="contact-split-left">
                            <ContactForm alert={showAlert} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import { getAccountData } from '../../api/firebaseApi';
import { initialAccount } from '../../components/Utils/JsonConstants';
import { AdminDashboardHeader } from '../../components/Headers/AdminDashboardHeader';
import { NotFoundRaw } from '../NotFound/NotFoundRaw';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { GoChecklist } from 'react-icons/go';
import { MdFastfood } from 'react-icons/md';
import { FaTools } from 'react-icons/fa';
import { AttendanceDashboard } from '../../components/AdminDashboards/AttendanceDashboard';
import { AdminViewAttendanceForm } from '../../components/AttendanceActions/AdminViewAttendanceForm';
import { AdminViewFoodOrderForm } from '../../components/AttendanceActions/AdminViewFoodOrderForm';
import { FoodOrdersDashboard } from '../../components/AdminDashboards/FoodOrdersDashboard';
import { AdminToolsDashboard } from '../../components/AdminDashboards/AdminToolsDashboard';
import './Admin.scss';

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/admin.jpg'); 
        transition: background-image 1s ease-in-out;
    }
`;

// Define alternate screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
};

// Page for Dashboard UI
export default function Admin(props) {
    // Get props and initalize state variables
    const { account, setAccount, sideBarOpen, alert, user } = props;
    const { nav, action } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    const [notFound] = useState(
        nav !== undefined &&
        nav !== 'attendance' &&
        nav !== 'food-orders' &&
        nav !== 'tools'
    );
    const [title, setTitle] = useState('Admin Dashboard Home');
    const [hasAdminAccess, setHasAdminAccess] = useState(undefined);
    const [screenSize, setScreenSize] = useState(screens);
    const navigate = useNavigate();
    
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    const headerAlertHandler = (payload) => {
        alert(payload)
    };

    const headerAlertHandlerCall = useCallback((payload) => {
        alert(payload);
    }, [alert]);

    const determineTitle = useCallback(() => {
        if (nav !== undefined) {
            if (nav === 'attendance') {
                setTitle('Attendance Dashboard');
            } else if (nav === 'food-orders') {
                setTitle('Food Order Dashboard');
            } else if (nav === 'tools') {
                setTitle('Admin Tools');
            }
        } else {
            setTitle('Admin Dashboard');
        }
    }, [nav]);

    // useEffect to get user account data
    useEffect(() => {
        const fetch = async () => {
            if (!account) {
                const rtnData = await getAccountData(localStorage.getItem('email'));
                if (!rtnData[0]) {
                    console.error(rtnData[1]);
                    // Show floating error alert message
                    const message = {
                        id: uuidv4(),
                        type: 'error',
                        message: `${rtnData[1]}`,
                    };
                    if (rtnData[1].code !== 'permission-denied') {
                        headerAlertHandlerCall(message);
                        setAccount(initialAccount);
                    }
                } else {
                    setAccount(rtnData[1]);
                    localStorage.setItem('account', JSON.stringify(rtnData[1]));
                }
            }
        }
        fetch();
    }, [account, headerAlertHandlerCall, setAccount]);

    useEffect(() => determineTitle(), [determineTitle]);

    // Define admin
    const getAdmin = useCallback(() => {

        let admin = localStorage.getItem('admin');
        if (!admin) {
            admin = account.Admin;
        } else if (admin !== 'All') {
            admin = JSON.parse(admin);
        }
        if (!admin) {
            navigate('/access-denied?reason=no-admin-access')
        } else if (admin === 'All') {
            setHasAdminAccess(true);
        } else {
            let adminCount = 0;
            for (let key in admin) {
                if (admin.hasOwnProperty(key) && admin[key] === true) { 
                    adminCount++;
                }
            }

            if (adminCount > 0) {
                setHasAdminAccess(true);
            } else {
                setHasAdminAccess(false);
            }
        }
    }, [navigate, account.Admin]);

    useEffect(() => {
        getAdmin()
    }, [getAdmin]);
    
    return (
        <section 
            id="admin-dashboard-section" 
            style={!screenSize.small 
                ? { width: sideBarOpen ? '85%' : '97%' } 
                : {}
            }
        >
            <Helmet>
                <title>Chucktown Sound | Admin Dashboard</title>
                <style>{bodyStyle}</style>
            </Helmet>
            
            <AdminDashboardHeader 
                screens={screens}
                title={title}
                alert={headerAlertHandler}
                user={user}
                account={account}
                sideBarOpen={sideBarOpen}
            />

            <div id="admin-dashboard-wrapper-div" className="admin-dashboard-wrapper-div"> 
                {hasAdminAccess === undefined && (
                    <>
                        <Oval /> 
                        <h1 style={{marginLeft: '10px'}}>Loading...</h1>
                    </>
                )}

                {hasAdminAccess === false && (
                    <Navigate to="/access-denied?reason=no-admin-access" />
                )}

                {!!hasAdminAccess && nav === undefined && (       
                    <div id="dashboard-content" className="admin-dashboard-content">
                        <Link to="/admin/dashboard/attendance">
                            <div className="admin-card-button">
                                <h1>Attendance Dashboard</h1>
                                <GoChecklist />
                            </div>
                        </Link>
                        <Link to="/admin/dashboard/food-orders">
                            <div className="admin-card-button">
                                <h1>Food Orders Dashboard</h1>
                                <MdFastfood />
                            </div>
                        </Link>
                        <Link to="/admin/dashboard/tools">
                            <div className="admin-card-button">
                                <h1>Admin Tools</h1>
                                <FaTools />
                            </div>
                        </Link>
                    </div>
                )}

                {!!hasAdminAccess && (nav === 'attendance' && action === undefined) && (
                    <div id="admin-dashboard-content" className="admin-dashboard-content">
                        <AttendanceDashboard alert={headerAlertHandler} account={account} />
                    </div>
                )}


                {!!hasAdminAccess && (nav === 'attendance' && action === 'view') && (
                    <div id="admin-dashboard-content" className="admin-dashboard-content">
                        <AdminViewAttendanceForm alert={headerAlertHandler} uuid={uuid} />
                    </div>
                )}

                {!!hasAdminAccess && (nav === 'food-orders' && action === undefined) && (
                    <div id="admin-dashboard-content" className="admin-dashboard-content">
                        <FoodOrdersDashboard alert={headerAlertHandler} account={account} />
                    </div>
                )}

                {!!hasAdminAccess && (nav === 'food-orders' && action === 'view') && (
                    <div id="admin-dashboard-content" className="admin-dashboard-content">
                        <AdminViewFoodOrderForm alert={headerAlertHandler} uuid={uuid} />
                    </div>
                )}

                {!!hasAdminAccess && nav === 'tools' && (
                    <div id="admin-dashboard-content" className="admin-dashboard-content admin-tools">
                        <AdminToolsDashboard alert={headerAlertHandler} />
                    </div>
                )}

                {!!hasAdminAccess && notFound && (
                    <div id="admin-dashboard-content" className="admin-dashboard-content">
                        <NotFoundRaw />
                    </div>
                )}
            </div>
        </section>
    );
};

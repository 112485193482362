import { 
    collection, 
    deleteDoc,
    doc, 
    getDoc, 
    updateDoc,
    query,
    where,
    getDocs,
    setDoc,
    orderBy,
} from 'firebase/firestore';
import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword, 
    sendPasswordResetEmail, 
    signOut,
    sendEmailVerification,
} from 'firebase/auth';
import StartFire, { GetAuth, GetAppCheck } from '../components/FirebaseConfig';
import { getToken } from 'firebase/app-check';
import axios from 'axios';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { determineDST, timer } from '../components/Utils/Timer';

const db = StartFire();
let auth = GetAuth();

// API to send email confirmation
export const sendConfirmationEmail = async (type, formState, split) => {
    let returnPayload = [true, null];
    let confirmationPayload = {};
    let copyPayload = {};
    if (type === 'Contact') {
        confirmationPayload = {
            to: [formState.email],
            message: {
                subject: 'CTS CONTACT CONFIRMATION',
                text: `
                    Hello, ${split[0]}!
                
                    Thank you so much for contacting us! This email is to confirm your submission to our contact form system. Your message is very important to us! If you do not hear from a member of our staff within 5 business days, please reach out directly to our director, Nick Nafpliotis at nnafplio@cofc.edu or reply to this email.

                    Here is a copy of your message:
                    Message: ${formState.message}
                    Submission Date: ${formState.date}

                    We are looking forward to answering your message!
                    
                    Sincerly,
                    Chucktown Sound IT 
                `,
                html: `
                    <section>
                        <div><p>Hello, ${split[0]}!</p></div>
                        <div>
                            <p>
                                Thank you so much for contacting us! This email is to confirm your submission to our contact form system. Your message is very important to us! If you do not hear from a member of our staff within 5 business days, please reach out directly to our director, Nick Nafpliotis at nnafplio@cofc.edu or reply to this email.
                                <br><br>
                                Here is a copy of your message:
                                <br>
                                Message: ${formState.message}
                                <br>
                                Submission Date: ${formState.date}
                                <br><br>
                                We are looking forward to answering your message!
                                <br><br>
                                Sincerly,
                                <br>
                                Chucktown Sound IT
                            </p>
                        </div>
                    </section>
                `,
            }
        }
    } else if (type === 'Attendance') {
        confirmationPayload = {
            to: [formState.Email],
            message: {
                subject: 'CTS ATTENDANCE SUBMISSION CONFIRMATION',
                text: `
                    Hello, ${formState.FirstName}!
                
                    You have successfully submitted to:
    
                    ${formState.Title}
                    (${split[0]})
                    
                    Here is your submission info:
                    
                    Submission UUID: ${formState.SubmissionUuid}
                    First Name: ${formState.FirstName}
                    Last Name: ${formState.LastName}
                    Timestamp: ${formState.Timestamp}
                
                    ** PLEASE DO NOT REPLY TO THIS EMAIL **
                `,
                html: `
                <section>
                    <div><p>Hello, ${formState.FirstName}!</p></div>
                    <div>
                    <p>
                        You have successfully submitted to:
                        <br><br>
                        ${formState.Title}
                        <br>
                        (${split[0]})
                        <br><br>
                        Here is your submission info:
                        <br>
                        <br>
                        Submission UUID: ${formState.SubmissionUuid}
                        <br>
                        First Name: ${formState.FirstName}
                        <br>
                        Last Name: ${formState.LastName}
                        <br>
                        Timestamp: ${formState.Timestamp}
                        <br>
                    </p>
                    </div>
                    <div><p>** PLEASE DO NOT REPLY TO THIS EMAIL **</p></div>
                </section>
                `,
            }
        }

        copyPayload = {
            to: ['chucktownsound.cofc@gmail.com'],
            message: {
                subject: 'NEW CTS ATTENDANCE SUBMISSION',
                text: `
                    Hello!
                
                    You have a new submission to:
    
                    ${formState.Title}
                    (${split[0]})
                    
                    Here is the submission info:
                    
                    Submission UUID: ${formState.SubmissionUuid}
                    First Name: ${formState.FirstName}
                    Last Name: ${formState.LastName}
                    Timestamp: ${formState.Timestamp}
                
                    ** PLEASE DO NOT REPLY TO THIS EMAIL **
                `,
                html: `
                <section>
                    <div><p>Hello!</p></div>
                    <div>
                    <p>
                        You have a new submission to:
                        <br><br>
                        ${formState.formTitle}
                        <br>
                        (${split[0]})
                        <br><br>
                        Here is the submission info:
                        <br>
                        <br>
                        Submission UUID: ${formState.SubmissionUuid}
                        <br>
                        First Name: ${formState.FirstName}
                        <br>
                        Last Name: ${formState.LastName}
                        <br>
                        Timestamp: ${formState.Timestamp}
                        <br>
                    </p>
                    </div>
                    <div><p>** PLEASE DO NOT REPLY TO THIS EMAIL **</p></div>
                </section>
                `,
            }
        }
    } else if (type === 'FoodOrder') {
        const choiceName = Object.keys(formState.Choice)[0]
        confirmationPayload = {
            to: [formState.Email],
            message: {
                subject: 'CTS FOOD ORDER CONFIRMATION',
                text: `
                    Hello, ${formState.FirstName}!
                
                    Here is your order confirmation for:
    
                    ${formState.Title}
                    (${split[0]})
                    
                    -------- Your Info --------
                    
                    Submission UUID: ${formState.SubmissionUuid}
                    Name: ${formState.LastName}, ${formState.FirstName} 
                    Timestamp: ${formState.Timestamp}

                    -------- Your Order --------

                    Option Name: ${choiceName}
                    Entree: ${formState.Choice[choiceName].Entree}
                    Side: ${formState.Choice[choiceName].Side}
                    Drink: ${formState.Choice[choiceName].Drink}
                    Comments: ${!!formState.Comments ? formState.Comments : 'NONE'}
                
                    ** PLEASE DO NOT REPLY TO THIS EMAIL **
                `,
                html: `
                <section>
                    <div><p>Hello, ${formState.FirstName}!</p></div>
                    <div>
                    <p>
                        Here is your order confirmation for:
                        <br><br>
                        ${formState.Title}
                        <br>
                        (${split[0]})
                        <br><br>
                        -------- Your Info --------
                        <br><br>
                        Submission UUID: ${formState.SubmissionUuid}
                        <br>
                        Name: ${formState.LastName}, ${formState.FirstName} 
                        <br>
                        Timestamp: ${formState.Timestamp}
                        <br><br>
                        -------- Your Order --------
                        <br><br>
                        Option Name: ${choiceName}
                        <br>
                        Entree: ${formState.Choice[choiceName].Entree}
                        <br>
                        Side: ${formState.Choice[choiceName].Side}
                        <br>
                        Drink: ${formState.Choice[choiceName].Drink}
                        <br>
                        Comments: ${!!formState.Comments ? formState.Comments : 'NONE'}
                        <br>
                    </p>
                    </div>
                    <div><p>** PLEASE DO NOT REPLY TO THIS EMAIL **</p></div>
                </section>
                `,
            }
        }

        copyPayload = {
            to: ['chucktownsound.cofc@gmail.com'],
            message: {
                subject: 'NEW FOOD ORDER SUBMISSION',
                text: `
                    Hello!
                
                    You have a new food order for:
    
                    ${formState.Title}
                    (${split[0]})
                    
                    Here is the order info:
                    
                    Submission UUID: ${formState.SubmissionUuid}
                    Name: ${formState.LastName}, ${formState.FirstName} 
                    Timestamp: ${formState.Timestamp}
                    --------------------------------------------------
                    Option Name: ${choiceName}
                    Entree: ${formState.Choice[choiceName].Entree}
                    Side: ${formState.Choice[choiceName].Side}
                    Drink: ${formState.Choice[choiceName].Drink}
                    Comments: ${!!formState.Comments ? formState.Comments : 'NONE'}
                
                    ** PLEASE DO NOT REPLY TO THIS EMAIL **
                `,
                html: `
                <section>
                    <div><p>Hello!</p></div>
                    <div>
                    <p>
                        You have a new food order for:
                        <br><br>
                        ${formState.Title}
                        <br>
                        (${split[0]})
                        <br><br>
                        Here is the order info:
                        <br><br>
                        Submission UUID: ${formState.SubmissionUuid}
                        <br>
                        Name: ${formState.LastName}, ${formState.FirstName} 
                        <br>
                        Timestamp: ${formState.Timestamp}
                        <br>
                        --------------------------------------------------
                        <br>
                        Option Name: ${choiceName}
                        <br>
                        Entree: ${formState.Choice[choiceName].Entree}
                        <br>
                        Side: ${formState.Choice[choiceName].Side}
                        <br>
                        Drink: ${formState.Choice[choiceName].Drink}
                        <br>
                        Comments: ${!!formState.Comments ? formState.Comments : 'NONE'}
                        <br>
                    </p>
                    </div>
                    <div><p>** PLEASE DO NOT REPLY TO THIS EMAIL **</p></div>
                </section>
                `,
            }
        }
    }
    const mailRef = doc(db, 'Mail', dayjs().format('MM-DD-YYYY-h-mm-A'));
    const mailRefCopy = doc(db, 'Mail', dayjs().format('MM-DD-YYYY-h-mm-A-COP'));
    try {
        await setDoc(mailRef, confirmationPayload).then( async () => {
            console.log('Confirmation email queued.');
            if (type === 'Attendance' || type === 'FoodOrder') {
                await setDoc(mailRefCopy, copyPayload).then(() => console.log('Copy confirmation email queued.'));
            }
        });
    } catch(error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API for log in
export const sendLogIn = async (email, password) => {
    let returnPayload = [true, null];
    auth = GetAuth();
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch(error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API for log out
export const sendLogOut = async () => {
    let returnPayload = [true, null];
    auth = GetAuth();
    try {
        await signOut(auth);
    } catch(error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to send password reset email
export const sendPasswordReset = (email) => {
    let returnPayload = [true, null];
    auth = GetAuth();
    try {
        sendPasswordResetEmail(auth, email)
            .then(() => console.log(`Email Verification sent successfully to: ${email}`));
    } catch(error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get account data
export const getAccountData = async (email) => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Users', email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            returnPayload = [true, docSnap.data()];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to update instrument data
export const updateInstruments = async (email, instruments) => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Users', email);
        await updateDoc(docRef, {Instrument: instruments});
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get rehearsal time data
export const getRehearsalData = async () => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'UsefulInfo', 'RehearsalTimes');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            returnPayload = [true, docSnap.data()];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get leadership contact data
export const getLeadershipContacts = async () => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'UsefulInfo', 'LeadershipContacts');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            returnPayload = [true, docSnap.data()];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to update account info
export const updateAccount = async (payload) => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Users', `${payload.Email}`);
        await updateDoc(docRef, payload);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get all used emails
export const getExistingEmails = async () => {
    let returnPayload = [true, null];
    const emailsList = [];
    try {
        const collectionRef = collection(db, 'Emails');
        const q = query(collectionRef);
        const snapshot = await getDocs(q);
        snapshot.forEach((doc) => {
            emailsList.push(doc.data().Email);
        });
        returnPayload = [true, emailsList];
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get all used usernames
export const getExistingUsernames = async () => {
    let returnPayload = [true, null];
    const usernameList = [];
    try {
        const collectionRef = collection(db, 'Usernames');
        const q = query(collectionRef);
        const snapshot = await getDocs(q);
        snapshot.forEach((doc) => {
            usernameList.push(doc.data().Username);
        });
        returnPayload = [true, usernameList];
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to add user data to db
export const postUserData = async (email, payload) => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Users', email);
        await setDoc(docRef, payload);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to create user in Firebase
export const createUser = async (email, password) => {
    let returnPayload = [true, null];
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to send email verification
export const sendVerificationEmail = () => {
    const message = `Email verification sent successfully to: ${auth.currentUser.email}`;
    let returnPayload = [true, message];
    try {
        sendEmailVerification(auth.currentUser);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to update emails and usernames
export const updateEmailsAndUsernames = async (newEmail, newUsername) => {
    let returnPayload = [true, null];
    try {
        const emails = collection(db, 'Emails');
        const usernames = collection(db, 'Usernames');
        await setDoc(usernames, { Username: newUsername });
        await setDoc(emails, { Email: newEmail });
    } catch (error) {
        console.error(`Error updating email/username records:\n${error}`);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get the email verification status
export const getEmailVerificationStatus = () => {
    return auth.currentUser ? auth.currentUser.emailVerified : false;
};

// API to get user's first name
export const getFirstName = async (email) => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Users', email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            returnPayload = [true, docSnap.data().FirstName];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get QR Code link
export const getQRCodeLink = async () => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Links', 'QRScanLink');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) { 
            const rtnData = docSnap.data();
            returnPayload = [true, rtnData.URL];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get main donation link
export const getMainLink = async () => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Links', 'MainFundraisingLink');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) { 
            const rtnData = docSnap.data();
            returnPayload = [true, rtnData.URL];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get main donation link
export const getSnapRaiseLink = async () => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'Links', 'SnapRaiseLink');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) { 
            const rtnData = docSnap.data();
            returnPayload = [true, rtnData.URL];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get current roster
export const getCurrentRoster = async () => {
    let returnPayload = [true, null];
    try {
        const q = query(collection(db, 'CurrentRoster'));
        const querySnapshot = await getDocs(q);
        let currentRoster = [];
        querySnapshot.forEach((doc) => {
            let keyName = doc.id;
            let data = doc.data();
            currentRoster.push({'key': keyName, 'data': data});
        });
        returnPayload = [true, currentRoster]
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get all users
export const getAllRegisteredUsers = async () => {
    let returnPayload = [true, null];
    try {
        const q = query(collection(db, 'Users'));
        const querySnapshot = await getDocs(q);
        let users = [];
        querySnapshot.forEach((doc) => {
            let keyName = doc.id;
            let data = doc.data();
            users.push({'key': keyName, 'data': data});
        });
        returnPayload = [true, users]
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// Function to compare current roster to submissions
const removeAssignedUsers = (users, roster) => {
    let rtnArr = [];

    if (!!users && !!roster) {
        users.forEach(user => {
            const email = user.Email;
            const isAssigned = roster.some(item => item.Email === email);
            if (!isAssigned) {
                rtnArr.push({
                    FirstName: user.FirstName,
                    LastName: user.LastName,
                    Email: email,
                });
            }
        });
    }
    return rtnArr;
};

// API to get all users
export const getAllUnassignedUsers = async (orderedBy) => {
    let returnPayload = [true, null];
    try {
        const usersRef = collection(db, 'Users');
        const usersQuery = query(usersRef, orderBy(orderedBy));
        const users = await getDocs(usersQuery);

        const currentRosterRef = collection(db, 'CurrentRoster');
        const rosterQuery = query(currentRosterRef, orderBy(orderedBy));
        const currentRoster = await getDocs(rosterQuery);

        if (!!users && !!currentRoster) {
            const currentRosterList = [];
            currentRoster.forEach((doc) => {
                currentRosterList.push(doc.data());
            });

            const usersList = [];
            users.forEach((doc) => {
                usersList.push(doc.data());
            });

            const filteredData = removeAssignedUsers(usersList, currentRosterList);
            returnPayload = [true, filteredData];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to add user to Current Roster
export const assignToCurrentRoster = async (payload) => {
    let returnPayload = [true, null];
    try {
        for (let i = 0; i < payload.length; i++) {
            const data = payload[i];
            const uuid = data.Uuid;
            delete data.Uuid;
            const docRef = doc(db, 'CurrentRoster', uuid);
            await setDoc(docRef, data);
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to add user to Current Roster
export const unassignFromCurrentRoster = async (payload) => {
    let returnPayload = [true, null];
    try {
        for (let i = 0; i < payload.length; i++) {
            const uuid = payload[i];
            const docRef = doc(db, 'CurrentRoster', uuid);
            await deleteDoc(docRef);
        }
        
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// Check the input dates for errors
const checkStatus = (today, start) => {
    let returnVal = true;
    if (today != null && start != null) {
        const compareMonth = start.get('month') > today.get('month');
        const compareDay = start.get('date') > today.get('date');
        const compareYear = start.get('year') > today.get('year');
        const compareTime = start.format('HH:mm') > today.format('HH:mm');

        if (compareMonth || compareDay || compareYear || compareTime) {
            returnVal = false
        }
    }
    return returnVal
};

// API to create new attendance form
export const createNewAttendanceForm = async (formState) => {
    let returnPayload = [true, null];

    // Prepare data payload
    const payload = {
        CreatedBy: formState.Username,
        CreationDate: determineDST(dayjs().format('MM/DD/YYYY h:mm A')),
        ExpirationDate: determineDST(dayjs(formState.ExpirationDate).format('MM/DD/YYYY h:mm A')),
        StartDate: determineDST(dayjs(formState.StartDate).format('MM/DD/YYYY h:mm A')),
        Status: checkStatus(dayjs(), formState.StartDate),
        SubmissionCode: formState.EncryptedPin,
        Title: formState.Title,
    };

    try {
        const docRef = doc(db, 'AttendanceForms', formState.FormUUID);
        await setDoc(docRef, payload);
        const submissionsColRef = collection(docRef, 'Submissions');
        const docRef2 = doc(submissionsColRef, uuidv4());
        await setDoc(docRef2, {
          Email: 'admin@chucktownsound.com',
          FirstName: 'Submission',
          LastName: 'Initialization',
          Timestamp: dayjs().format('MM/DD/YYYY h:mm A'),
          Verified: true
        });
        returnPayload = [true, null]
    } catch (error) {
        console.error(error);
        returnPayload = [false, error.message];
    } finally {
        return returnPayload;
    }
};

// API to get all attendance forms
export const getAllAttendanceForms = async () => {
    let returnPayload = [true, null];
    try {
        const q = query(collection(db, 'AttendanceForms'));
        const querySnapshot = await getDocs(q);
        let attendanceForms = [];
        querySnapshot.forEach((doc) => {
            let keyName = doc.id;
            let data = doc.data();
            attendanceForms.push({'key': keyName, 'data': data});
        });
        returnPayload = [true, attendanceForms]
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// Function to compare current roster to submissions
const compareRoster = (submissions, roster) => {
    let rtnArr = [];

    if (!!submissions && !!roster) {
        // Create a map of roster for efficient lookups
        const submissionArr = [];
        for (const sub in submissions) {
            const subEmail = submissions[sub].data.Email;
            submissionArr.push(subEmail);
        }

        roster.forEach(doc => {
            const email = doc.Email;
            let submitted = false;
            submissionArr.forEach((item) => {
                if (item === email) {
                    submitted = true;
                }
            });

            rtnArr.push({
                FirstName: doc.FirstName,
                LastName: doc.LastName,
                Email: email,
                Submitted: submitted,
            });
        });
    }
    return rtnArr;
};

// API to get single attendance form
export const getSingleAttendanceForm = async (uuid, formOrderedBy, rosterOrderedBy) => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'AttendanceForms', uuid);
        const docSnap = await getDoc(docRef);
        const currentRosterRef = collection(db, 'CurrentRoster');
        const rosterQuery = query(currentRosterRef, orderBy(rosterOrderedBy));
        const currentRoster = await getDocs(rosterQuery);
        if (docSnap.exists() && !!currentRoster) {
            const currentRosterList = [];
            currentRoster.forEach((doc) => {
                currentRosterList.push(doc.data());
            });
            const attendanceData = docSnap.data();
            const submissionsColRef = collection(docRef, 'Submissions');
            const submissionQuery = query(submissionsColRef, orderBy(formOrderedBy));
            const querySnapshot = await getDocs(submissionQuery);
            const records = [];
            querySnapshot.forEach((doc) => {
                let keyName = doc.id;
                let data = doc.data();
                records.push({'key': keyName, 'data': data});
            });
            attendanceData.Submissions = records.filter((record) => record.data.FirstName !== 'Submission');
            attendanceData.ComparedRoster = compareRoster(attendanceData.Submissions, currentRosterList);
            returnPayload = [true, attendanceData];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get all open and unsigned attendance forms
export const getAllAvailableAttendanceForms = async (email) => {
    let returnPayload = [true, null];
    try {
        const attendanceFormsRef = collection(db, 'AttendanceForms');
        const attendanceFormsSnapshot = await getDocs(attendanceFormsRef);

        const unsubmittedForms = [];
        for (const formDoc of attendanceFormsSnapshot.docs) {
            const data = formDoc.data();
            data.key = formDoc.id;
            const submissionsRef = collection(formDoc.ref, 'Submissions');
            const submissionsQuery = query(submissionsRef, where('Email', '==', email));
            const submissionsSnapshot = await getDocs(submissionsQuery);

            if (data.Status && submissionsSnapshot.empty) {
                unsubmittedForms.push(data);
            }
        }

        returnPayload = [true, unsubmittedForms];
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get all open and unsigned attendance forms
export const getAllAvailableFoodOrderForms = async (email) => {
    let returnPayload = [true, null];
    try {
        const attendanceFormsRef = collection(db, 'FoodOrderForms');
        const attendanceFormsSnapshot = await getDocs(attendanceFormsRef);

        const unsubmittedForms = [];
        for (const formDoc of attendanceFormsSnapshot.docs) {
            const data = formDoc.data();
            data.key = formDoc.id;
            const submissionsRef = collection(formDoc.ref, 'Submissions');
            const submissionsQuery = query(submissionsRef, where('Email', '==', email));
            const submissionsSnapshot = await getDocs(submissionsQuery);

            if (data.Status && submissionsSnapshot.empty) {
                unsubmittedForms.push(data);
            }
        }

        returnPayload = [true, unsubmittedForms];
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};


// API to update attendance form
export const updateAttendanceForm = async (uuid, payload) => {
    let returnPayload = [true, null];

    try {
        const docRef = doc(db, 'AttendanceForms', uuid);
        await updateDoc(docRef, payload);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

export const signAttendanceForm = async (uuid, payload) => {
    let returnPayload = [true, null];
    const submissionUuid = payload.SubmissionUuid;
    try {
        const docRef = doc(db, 'AttendanceForms', uuid);
        const submissionsColRef = collection(docRef, 'Submissions');
        const targetForm = doc(submissionsColRef, submissionUuid);
        delete payload.SubmissionUuid;
        await setDoc(targetForm, payload);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
}

// API to delete an attendance form
export const deleteAttendanceForm = async (uuid) => {
    let returnPayload = [true, null];
    try {
        // First, delete all submissions in the Submissions collection
        const docRef = doc(db, 'AttendanceForms', uuid);
        const submissionsQuery = await getDocs(collection(docRef, 'Submissions'));

        for (const submissionDoc of submissionsQuery.docs) {
            await deleteDoc(submissionDoc.ref);
        }

        // Then, delete the attendance form itself
        await timer(2000).then(async () => {
            await deleteDoc(docRef);
        });
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to create new attendance form
export const createNewFoodOrderForm = async (uuid, payload, inititalSubmit) => {
    let returnPayload = [true, null];

    try {
        const docRef = doc(db, 'FoodOrderForms', uuid);
        await setDoc(docRef, payload);
        const submissionsColRef = collection(docRef, 'Submissions');
        const docRef2 = doc(submissionsColRef, uuidv4());
        await setDoc(docRef2, inititalSubmit);
        returnPayload = [true, null]
    } catch (error) {
        console.error(error);
        returnPayload = [false, error.message];
    } finally {
        return returnPayload;
    }
};

// API to get all food order forms
export const getAllFoodOrderForms = async () => {
    let returnPayload = [true, null];
    try {
        const q = query(collection(db, 'FoodOrderForms'));
        const querySnapshot = await getDocs(q);
        let foodOrderForms = [];
        querySnapshot.forEach((doc) => {
            let keyName = doc.id;
            let data = doc.data();
            foodOrderForms.push({'key': keyName, 'data': data});
        });
        returnPayload = [true, foodOrderForms]
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to get single food order form
export const getSingleFoodOrderForm = async (uuid, orderedBy) => {
    let returnPayload = [true, null];
    try {
        const docRef = doc(db, 'FoodOrderForms', uuid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) { 
            let rtnData = docSnap.data();
            const submissionsColRef = collection(docRef, 'Submissions');
            const q = query(submissionsColRef, orderBy(orderedBy));
            const querySnapshot = await getDocs(q);
            let records = [];
            querySnapshot.forEach((doc) => {
                let keyName = doc.id;
                let data = doc.data();
                records.push({'key': keyName, 'data': data});
            });
            rtnData.Submissions = records.filter((record) => record.data.FirstName !== 'Submission');
            returnPayload = [true, rtnData];
        }
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to update food order form
export const updateFoodOrderForm = async (uuid, payload) => {
    let returnPayload = [true, null];

    try {
        const docRef = doc(db, 'FoodOrderForms', uuid);
        await updateDoc(docRef, payload);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to delete an attendance form
export const deleteFoodOrderForm = async (uuid) => {
    let returnPayload = [true, null];
    try {
        // First, delete all submissions in the Submissions collection
        const docRef = doc(db, 'FoodOrderForms', uuid);
        const submissionsQuery = await getDocs(collection(docRef, 'Submissions'));

        for (const submissionDoc of submissionsQuery.docs) {
            await deleteDoc(submissionDoc.ref);
        }

        // Then, delete the attendance form itself
        await timer(2000).then(async () => {
            await deleteDoc(docRef);
        });
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// API to submit a food order
export const placeFoodOrder = async (uuid, payload) => {
    let returnPayload = [true, null];
    const submissionUuid = payload.SubmissionUuid;
    try {
        const docRef = doc(db, 'FoodOrderForms', uuid);
        const submissionsColRef = collection(docRef, 'Submissions');
        const targetForm = doc(submissionsColRef, submissionUuid);
        delete payload.SubmissionUuid;
        await setDoc(targetForm, payload);
    } catch (error) {
        console.error(error);
        returnPayload = [false, error];
    } finally {
        return returnPayload;
    }
};

// Function to get token for cloud function API
const getAPIToken = async () => {
    try {
        const appCheck = GetAppCheck();
        const token = await getToken(appCheck, false);
        return token;
    } catch (error) {
        console.error(error);
        return '';
    }
};

// API to generate an attendance code
export const generateCode = async () => {
    let returnPayload = [true, null];
    const apiToken = await getAPIToken();
    try {
        const url = 'https://us-central1-chucktownsound-v2.cloudfunctions.net/app/generateAttendanceCode';
        const response = await axios.get(url, {
            headers: { 'X-Firebase-AppCheck': apiToken.token },
        });
        returnPayload = [true, response.data];
    } catch (error) {
        console.error(error);
        returnPayload = [false, error.message];
    } finally {
        return returnPayload;
    }
};

// API to verify an attendance code
export const verifyCode = async (uuid, code) => {
    let returnPayload = [true, null];
    const apiToken = await getAPIToken();
    try {
        const url = 'https://us-central1-chucktownsound-v2.cloudfunctions.net/app/verifyAttendanceCode';
        const response = await axios.post(url, { uuid, code }, {
            headers: { 'X-Firebase-AppCheck': apiToken.token },
        });
        returnPayload = [true, response.data];
    } catch (error) {
        console.error(error);
        const message = error.response.data.error;
        returnPayload = [false, message];
    } finally {
        return returnPayload;
    }
};

// API to verify ReCaptcha
export const recaptchaVerification = async (token) => {
    let returnPayload = [true, null];
    const apiToken = await getAPIToken();
    try {
        const url = 'https://us-central1-chucktownsound-v2.cloudfunctions.net/app/verifyRecaptcha';
        const response = await axios.post(url, { headers: apiToken.token, token });
        returnPayload = [true, response.data.success];
    } catch (error) {
        const message = error.message;
        const status = error.status;
        console.error(error);
        returnPayload = [
            false, 
            <>
                <span>{message}</span><br />
                <span>{status}</span><br />
            </>,
        ];
    } finally {
        return returnPayload;
    }
};

// API to get unencrypted submission code
export const getSubmissionCodeByUuid = async (uuid) => {
    let returnPayload = [true, null];
    const apiToken = await getAPIToken();
    try {
        const url = 'https://us-central1-chucktownsound-v2.cloudfunctions.net/app/getAttendanceCodeByUuid';
        const response = await axios.post(url, 
            { uuid }, 
            {headers: { 'X-Firebase-AppCheck': apiToken.token }}
        );
        returnPayload = [true, response.data];
    } catch (error) {
        console.error(error);
        returnPayload = [false, error.message];
    } finally {
        return returnPayload;
    }
};

// API to verify an attendance code
export const getEmailVerificationRecords = async () => {
    let returnPayload = [true, null];
    const apiToken = await getAPIToken();
    try {
        const url = 'https://us-central1-chucktownsound-v2.cloudfunctions.net/app/getEmailVerificationRecords';
        const response = await axios.get(url, {
            headers: { 'X-Firebase-AppCheck': apiToken.token },
        });
        returnPayload = [true, response.data];
    } catch (error) {
        console.error(error);
        const message = error.response.data.error;
        returnPayload = [false, message];
    } finally {
        return returnPayload;
    }
};

// API to verify an attendance code
export const getAllUserInfo = async () => {
    let returnPayload = [true, null];
    const apiToken = await getAPIToken();
    try {
        const url = 'https://us-central1-chucktownsound-v2.cloudfunctions.net/app/getAllUserRecords';
        const response = await axios.get(url, {
            headers: { 'X-Firebase-AppCheck': apiToken.token },
        });
        returnPayload = [true, response.data];
    } catch (error) {
        console.error(error);
        const message = error.message;
        returnPayload = [false, message];
    } finally {
        return returnPayload;
    }
};

import React from 'react';
import { MdClose } from 'react-icons/md';
import './Modals.scss';
import './FormActionModal.scss';
import { UpdateFromTimesForm } from '../Forms/UpdateFormTimes.tsx';


export function FormActionModal(props) {
    // Get props and initalize state variables
    const { alert, closeHandler, refresh, configs, formData } = props;
    
    const handleClose = (action) => {
        closeHandler(action)
    };

    const alertHandler = (payload) => {
        alert(payload);
    };

    const refreshHandler = () => {
        refresh();
    };

    return (
        <>
            <div id="form-action-modal" className="form-action-modal">
                <div className="form-action-modal-content">
                    <div id="close-action" className="modal-close" onClick={handleClose}>
                        <MdClose />
                    </div>
                    <UpdateFromTimesForm 
                        alert={alertHandler}
                        close={handleClose}
                        refresh={refreshHandler}
                        formData={formData}
                        configs={configs}
                    />
                </div>
            </div>
        </>
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getAllUserInfo } from '../../api/firebaseApi';
import { Oval } from 'react-loading-icons';
import { FaSearch } from 'react-icons/fa';
import { FcInfo } from 'react-icons/fc';
import { Chip, TextField, InputAdornment } from '@mui/material';
import { DataTablePagination } from '../TablePagination/DataTablePagination';

export function ManageUsers(props) {
    const { alert } = props;
    const [storedData, setStoredData] = useState([])
    const [userData, setUserData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [length, setLength] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [isSelected, setIsSelected] = useState(false);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Determines number of empty data rows
    const emptyRows = page >= 0 
        ? Math.max(0, (1 + page) * rowsPerPage - length) 
        : 0
    ;

    // Table pagination page change handler
    const handlePageChange = (event, newPage) => {
        event.preventDefault();
        setPage(newPage);
    };

    // Table pagination rows per page change handler
    const handleRowsPerPageChange = (event) => {
        const { value } = event.target;
        setRowsPerPage(parseInt(value, 10));
        setPage(0);
    };

    const alertHandler = useCallback((payload) => {
        alert(payload);
    }, [alert]);

    // TODO: Remove this after view is implemented
    const notify = () => {
        const payload = {
            id: uuidv4(),
            type: 'error',
            message: 'This function is not yet available.'
        }
        alert(payload);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        const data = [...storedData];
        const filteredData = data.filter((row) => row.LastName.toLowerCase().includes(searchText));
        if (filteredData.length === 0) {
            setUserData([]);
            setLength(1);
        } else {
            setUserData(filteredData);
            setLength(filteredData.length);
        }
        setPage(0);
    };

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            const data = await getAllUserInfo();
            if (data[0]) {
                setUserData(data[1]);
                setLength(data[1].length);
                setStoredData(data[1]);
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: data[1],
                }
                alertHandler(payload);
                setLength(1);
            }
            setIsLoading(false);
        }
        if (length === 0 ) {
            fetch();
        }
    }, [alertHandler, length]);

    const updateSearchControl = (event) => {
        const { value } = event.target;
        setSearchText(value);
    };

    const searchAdornment = isSelected
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <FaSearch style={{fontSize: "110%"}} className="snow" />
            </InputAdornment>
        )
    } : {};

    return (
        <>
            <div className="search-div">
                <TextField 
                    id="search" 
                    label="Search"
                    variant="outlined"
                    type="text"
                    onChange={updateSearchControl}
                    value={searchText}
                    InputProps={searchAdornment}
                    onFocus={e => setIsSelected(true)}
                    onBlur={searchText ? undefined : e => setIsSelected(false)}
                    onInput={e => setIsSelected(true)}
                    className="search-box"
                />
                <button className="main-button" onClick={handleSearch}>Search</button>
            </div>
            <div className="data-table-div">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {length > 0 && (rowsPerPage > 0
                                ? userData.slice(startIndex, endIndex)
                                : userData
                            ).map((row, index) => {
                                const hasPhone = row.Phone.toLowerCase() !== 'none';
                                const dial = hasPhone ? `tel:${row.Phone.replaceAll('-', '')}` : '#';
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{row.LastName}, {row.FirstName}</td>
                                        <td>{row.Email}</td>
                                        <td><a href={dial}>{row.Phone}</a></td>
                                        <td>
                                            <button 
                                                className="main-button" 
                                                onClick={notify}
                                            >
                                                VIEW
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        {(length !== 0 && emptyRows > 0) && (
                            <tr style={{ height: 25.5 * emptyRows }}>
                                <td colSpan={6} aria-hidden />
                            </tr>
                        )}
                        {length === 0 && (
                            <tr>                                
                                {isLoading 
                                    ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                    : <td colSpan={6}>
                                        <Chip 
                                            className="MuiChip-root MuiChip-root info-table"
                                            icon={<FcInfo />} 
                                            label="No Users to Display" 
                                            variant="outlined" 
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
                {length > 0 && (
                    <DataTablePagination
                        length={length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                    />
                )}
            </div>
        </>
    )
};

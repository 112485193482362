import React from 'react';
import { MdClose } from 'react-icons/md';
import { SignAttendanceForm } from '../Forms/SignAttendanceForm.tsx';
import { SubmitFoodOrderForm } from '../Forms/SubmitFoodOrderForm.tsx';
import './Modals.scss';
import './SignFormModal.scss';

export function SignFormModal(props) {
    // Get props and initalize state variables
    const { 
        alert, 
        closeHandler, 
        refresh, 
        uuid, 
        userData, 
        title,
        type,
        options
    } = props;
    
    const handleClose = () => {
        closeHandler();
    };

    const alertHandler = (payload) => {
        alert(payload);
    };

    const refreshHandler = () => {
        refresh();
    };

    return (
        <>
            <div id="sign-form-modal" className="sign-form-modal">
                <div className={`sign-form-modal-content${type ===  'food-order' ? ' food-order' : ''}`}>
                    <div id="close-sign" className="modal-close" onClick={handleClose}>
                        <MdClose />
                    </div>
                    {type === 'attendance' && (
                        <SignAttendanceForm 
                            alert={alertHandler}
                            close={handleClose}
                            refresh={refreshHandler}
                            userData={userData}
                            uuid={uuid}
                            title={title}
                        />
                    )}

                    {type === 'food-order' && (
                        <SubmitFoodOrderForm 
                            alert={alertHandler}
                            close={handleClose}
                            refresh={refreshHandler}
                            userData={userData}
                            uuid={uuid}
                            title={title}
                            options={options}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

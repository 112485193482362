import React, { 
    ChangeEvent, 
    FormEvent, 
    useEffect,
    useState 
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { 
    Box, 
    Card, 
    CardContent, 
    TextField, 
    InputAdornment, 
    InputLabel, 
    FormControl, 
    Select, 
    MenuItem 
} from '@mui/material';
import { updateAccount } from '../../api/firebaseApi';
import { BsPersonCircle } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { CgScreen } from 'react-icons/cg';
import '../../styles/forms.scss';

// Define screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
    tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
    medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
    large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
    xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
};

type FormState = {
    Active: boolean;
    Admin: {
      AdminRequests: boolean;
      Announcements: boolean;
      AttendanceForms: boolean;
      Awards: boolean;
      FoodOrders: boolean;
      Members: boolean;
      PhysicalInventory: boolean;
      Users: boolean;
    },
    Alum: boolean,
    BirthDay: number,
    BirthMonth: number,
    CreatedOn: string,
    FirstName: string,
    GradSemester: string,
    GradYear: number,
    Instrument: Array<string>,
    LastName: string,
    Rookie: boolean,
    Staff: boolean,
    Student: string | boolean,
    Username: string,
    Verified: boolean,
    Year: string,
    OtherYear: string,
    Email: string
};
const days = Array(32).fill(0).map((n, i) => n + i);
const regex = new RegExp('^[0-9]{4}$');

export function AccountEditForm(props: { alert: Function; account: FormState | any; closeHandler: Function; }) {

    // Get the component props and initialize useStates
    const { alert, account, closeHandler } = props;

    const primeAccountType = () => {
        const updatedFormState = { ...account };
        if (account.Student) { 
            updatedFormState.Student = 'Student';
        }
        if (account.Staff) {
            updatedFormState.Student = 'Staff';
        }
        if (account.Alum) {
            updatedFormState.Student = 'Alumni';
        }
        if (account.Staff && account.Alum) {
            updatedFormState.Student = 'Alumni & Staff';
        }
        return updatedFormState;
    };

    const [formState, setFormState] = useState<FormState>(primeAccountType());
    const [screenSize, setScreenSize] = useState<any>(screens);
    const [fieldWidth, setFieldWidth] = useState<number>(400/1.07);
    const [isSelected1, setIsSelected1] = useState<boolean>(false);
    const [isSelected2, setIsSelected2] = useState<boolean>(false);
    const [isSelected3, setIsSelected3] = useState<boolean>(false);
    const [isSelected4, setIsSelected4] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    // Function to show a message from form
    const showAlert = (alertPayload: object | Array<object>) => {
        alert(alertPayload);
    };

    const handleCancel = () => {
        closeHandler(false);
    };

    // useEffect for screen size changes
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
            tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
            medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
            large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
            xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSize}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, [screenSize]);

    // useEffect for changing state variables based on screen size
    useEffect(() => {
        if (screenSize.small) {
            setFieldWidth(250/1.18);
        } else if (screenSize.tabletPort) {
            setFieldWidth(350/1.07);
        } else if (screenSize.tabletLand) {
            setFieldWidth(400/1.07);
        } else if (screenSize.large) {
            setFieldWidth(500/1.07);
        } else if (screenSize.xlarge) {
            setFieldWidth(500/1.07);
        } else {
            setFieldWidth(400/1.2);
        }
    }, [screenSize]);

    const handleSave = async (event: FormEvent) => {
        event.preventDefault();
        setSaving(true);
        let student = true;
        let staff = false;
        let alum = false;
        let gradSemester = formState.GradSemester;
        if (formState.Student === 'Alumni & Staff') {
            student = false;
            staff = true;
            alum = true;
            gradSemester = 'None';
        }
        else if (formState.Student === 'Staff') {
            student = false;
            staff = true;
            alum = true;
            gradSemester = 'None';
        }
        else if (formState.Student === 'Alumni') {
            student = false;
            alum = true;
        }
        const payload = {
            Active: formState.Active,
            Admin: formState.Admin,
            Alum: alum,
            BirthDay: formState.BirthDay,
            BirthMonth: formState.BirthMonth,
            CreatedOn: formState.CreatedOn,
            Email: formState.Email,
            FirstName: formState.FirstName,
            GradSemester: gradSemester,
            GradYear: formState.GradYear,
            Instrument: formState.Instrument,
            LastName: formState.LastName,
            OtherYear: formState.OtherYear,
            Rookie: formState.Rookie,
            Staff: staff,
            Student: student,
            Username: formState.Username,
            Verified: formState.Verified,
            Year: formState.Year,
        };

        const failures: string[] = [];
        const keys = Object.keys(payload);
        for (let i = 0; i < keys.length; i++) {
            const item = payload[keys[i]];
            if (item === undefined || item === null) {
                failures.push(`Field '${keys[i]} cannot be empty.`);
            }
        }
        if (failures.length === 0) {
            const sendUpdate = await updateAccount(payload);
            if (!sendUpdate[0]) {
                // Show floating error alert message
                const message = {
                    id: uuidv4(),
                    type: 'error',
                    message: `${sendUpdate[1]}`,
                };
                showAlert(message);
            } else {
                // Show floating success alert message
                const message = {
                    id: uuidv4(),
                    type: 'success',
                    message: 'Success! Account updated.',
                };
                showAlert(message);
                handleCancel();
            }
        } else {
            // Populate error messages
            const message: Object[] = [];
            for (let i = 0; i < failures.length; i++) {
                message.push({
                    id: uuidv4(),
                    type: 'error',
                    message: `${failures[i]}`,
                });
            } 
            // Show floating error alert message
            showAlert(message);
        }
        setSaving(false);
    };

    // Other selection function
    function isOther() {
        let other = false;
        if (formState.OtherYear === 'Other') {
            other = true;
        }
        return other;
    }

    // Student selection function
    function isStudent() {
        let student = false;
        if (formState.Student === 'Student' || formState.Student === true) {
            student = true;
        }

        if (formState.Year === 'Other' && formState.Student === false) {
            formState.Year = 'None';
        }
        return student;
    }

    // Alumni selection function
    function isAlum() {
        let alum = false;
        if (formState.Student === "Alumni" || formState.Student === "Alumni & Staff") {
            alum = true;
        }
        return alum;
    };

    // Form controllers
    const updateFormControl = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = event.target;
        const key = id as keyof FormState;
        const updatedFormState = { ...formState };
        updatedFormState[key] = value;
        if(key === 'GradYear') {
            if (updatedFormState['GradYear'] < 1000 
                || updatedFormState['GradYear'] >= 2100) { 
                // Show floating error alert message
                const message = {
                    id: uuidv4(),
                    type: 'error',
                    message: 'Please enter a valid year.',
                };
                showAlert(message);
            }
        }
        setFormState(updatedFormState);
    };

    const updateFormControlSelect = (
        event: React.ChangeEvent<{ value: number }>
    ) => {
        const { value }  = event.target;
        const updatedFormState = { ...formState };
        updatedFormState['BirthMonth'] = value as number;
        setFormState(updatedFormState);
    };

    const updateFormControlSelect2 = (
        event: React.ChangeEvent<{ value: number }>
    ) => {
        const { value }  = event.target;
        const updatedFormState = { ...formState };
        updatedFormState['BirthDay'] = value as number;
        setFormState(updatedFormState);
    };

    const updateFormControlSelect3 = (
        event: React.ChangeEvent<{ value: string }>
    ) => {
        const { value }  = event.target;
        const updatedFormState = { ...formState };
        updatedFormState['Student'] = value as string;
        setFormState(updatedFormState);
    };

    const updateFormControlSelect4 = (
        event: React.ChangeEvent<{ value: string }>
    ) => {
        const { value }  = event.target;
        const updatedFormState = { ...formState };
        updatedFormState['Year'] = value as string;
        setFormState(updatedFormState);
    };

    const updateFormControlSelect5 = (
        event: React.ChangeEvent<{ value: string }>
    ) => {
        const { value }  = event.target;
        const updatedFormState = { ...formState };
        updatedFormState['GradSemester'] = value as string;
        setFormState(updatedFormState);
    };

    const updateFormControlSelect6 = (
        event: React.ChangeEvent<{ value: boolean }>
    ) => {
        const { value }  = event.target;
        const updatedFormState = { ...formState };
        updatedFormState['Rookie'] = value as boolean;
        setFormState(updatedFormState);
    };

    // First Name Adornment
    const firstAdornment = isSelected1
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <BsPersonCircle style={{fontSize: "110%"}}/>
            </InputAdornment>
        )
        }
    : {};

    // Last Name Adornment
    const lastAdornment = isSelected2
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <BsPersonCircle style={{fontSize: "110%"}}/>
            </InputAdornment>
        )
        }
    : {};

    // Email Adornment
    const emailAdornment = isSelected3
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <IoIosMail style={{fontSize: "110%"}}/>
            </InputAdornment>
        )
        }
    : {};

    // Username Adornment
    const userAdornment = isSelected4
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <CgScreen style={{fontSize: "110%"}}/>
            </InputAdornment>
        )
        }
    : {};

    return (
        <>
            <h2>Edit Account</h2>
            <div>
                <Box>
                    <Card>
                        <form>
                            <CardContent> 
                                {/* First Name */}
                                <div className="padded">
                                    <TextField 
                                        required
                                        id="FirstName" 
                                        label="First Name"
                                        variant="outlined"
                                        type="text"
                                        onChange={updateFormControl}
                                        value={formState.FirstName} 
                                        sx={{width: fieldWidth}}
                                        InputProps={firstAdornment}
                                        onFocus={e => setIsSelected1(true)}
                                        onBlur={formState.FirstName ? undefined : e => setIsSelected1(false)}
                                        onInput={e => setIsSelected1(true)}
                                    />
                                </div>

                                {/* Last Name */}
                                <div className="padded">
                                    <TextField 
                                        required
                                        id="LastName" 
                                        label="Last Name"
                                        variant="outlined"
                                        type="text"
                                        onChange={updateFormControl}
                                        value={formState.LastName} 
                                        sx={{width: fieldWidth}}
                                        InputProps={lastAdornment}
                                        onFocus={e => setIsSelected2(true)}
                                        onBlur={formState.LastName ? undefined : e => setIsSelected2(false)}
                                        onInput={e => setIsSelected2(true)}
                                    />
                                </div>

                                {/* Email */}
                                <div className="padded">
                                    <TextField 
                                        disabled
                                        id="Email" 
                                        label="Email"
                                        variant="outlined"
                                        type="text"
                                        helperText="Editing your email is not yet available."
                                        onChange={updateFormControl}
                                        value={formState.Email} 
                                        sx={{width: fieldWidth}}
                                        InputProps={emailAdornment}
                                        onFocus={e => setIsSelected3(true)}
                                        onBlur={formState.Email ? undefined : e => setIsSelected3(false)}
                                        onInput={e => setIsSelected3(true)}
                                    />
                                </div>

                                {/* Username */}
                                <div className="padded">
                                    <TextField 
                                        disabled
                                        id="Username" 
                                        label="Username"
                                        variant="outlined"
                                        type="text"
                                        helperText="Editing your username is not yet available."
                                        onChange={updateFormControl}
                                        value={formState.Username} 
                                        sx={{width: fieldWidth}}
                                        InputProps={userAdornment}
                                        onFocus={e => setIsSelected4(true)}
                                        onBlur={formState.Username ? undefined : e => setIsSelected4(false)}
                                        onInput={e => setIsSelected4(true)}
                                    />
                                </div>

                                {/* Birthday */}
                                <div className="padded center">
                                    <InputLabel>Birthday</InputLabel>
                                </div>
                                <div className="padded side">
                                    <div>
                                        <FormControl>
                                            <InputLabel>Month</InputLabel>
                                            <Select
                                                labelId="year-label"
                                                id="BirthMonth"
                                                variant="outlined"
                                                value={formState?.BirthMonth}
                                                label="Month"
                                                onChange={updateFormControlSelect}
                                            >
                                                <MenuItem value={0}>-</MenuItem>
                                                <MenuItem value={1}>January</MenuItem>
                                                <MenuItem value={2}>February</MenuItem>
                                                <MenuItem value={3}>March</MenuItem>
                                                <MenuItem value={4}>April</MenuItem>
                                                <MenuItem value={5}>May</MenuItem>
                                                <MenuItem value={6}>June</MenuItem>
                                                <MenuItem value={7}>July</MenuItem>
                                                <MenuItem value={8}>August</MenuItem>
                                                <MenuItem value={9}>September</MenuItem>
                                                <MenuItem value={10}>October</MenuItem>
                                                <MenuItem value={11}>November</MenuItem>
                                                <MenuItem value={12}>December</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl>
                                        <InputLabel>Day</InputLabel>
                                            <Select
                                                labelId="year-label"
                                                id="BirthDay"
                                                variant="outlined"
                                                value={formState?.BirthDay}
                                                label="Day"
                                                onChange={updateFormControlSelect2}
                                            >
                                                {days.map((day, index) => {
                                                let val = day;
                                                if (day === 0) {day = '-'}
                                                    return <MenuItem key={index} value={val}>{day}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {/* Account Type */}
                                <div className="padded">
                                    <FormControl>
                                        <InputLabel required id="year-label">Account Type</InputLabel>
                                        <Select
                                            required
                                            labelId="year-label"
                                            id='Student'
                                            name="Student"
                                            variant="outlined"
                                            value={formState?.Student}
                                            label="Account Type"
                                            onChange={updateFormControlSelect3}
                                        >
                                            <MenuItem value={'Student'}>Student</MenuItem>
                                            <MenuItem value={'Staff'}>Staff</MenuItem>
                                            <MenuItem value={'Alumni'}>Alumni</MenuItem>
                                            <MenuItem value={'Alumni & Staff'}>Alumni & Staff</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {/* Year */}
                                {isStudent() && (
                                    <div className="padded">
                                        <FormControl>
                                            <InputLabel id="year-label">Academic Year</InputLabel>
                                            <Select
                                                labelId="year-label"
                                                name="Year"
                                                variant="outlined"
                                                value={formState?.Year}
                                                label="Academic Year"
                                                onChange={updateFormControlSelect4}
                                                sx={{width: fieldWidth}}
                                            >
                                                <MenuItem value={'None'}>None</MenuItem>
                                                <MenuItem value={'Freshman'}>Freshman</MenuItem>
                                                <MenuItem value={'Sophomore'}>Sophomore</MenuItem>
                                                <MenuItem value={'Junior'}>Junior</MenuItem>
                                                <MenuItem value={'Senior'}>Senior</MenuItem>
                                                <MenuItem value={'Rising-Freshman'}>Rising Freshman</MenuItem>
                                                <MenuItem value={'Rising-Sophomore'}>Rising Sophomore</MenuItem>
                                                <MenuItem value={'Rising-Junior'}>Rising Junior</MenuItem>
                                                <MenuItem value={'Rising-Senior'}>Rising Senior</MenuItem>
                                                <MenuItem value={'Other'}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}

                                {/* Selected Other */}
                                {isOther() && (
                                    <div className="padded">
                                        <TextField 
                                        required
                                        id="OtherYear" 
                                        label="Please Specify Other" 
                                        variant="outlined"
                                        onChange={updateFormControl}
                                        value={formState?.OtherYear} 
                                        sx={{width: getWidth()}}
                                        InputProps={userAdornment}
                                        onFocus={e => setIsSelected4(true)}
                                        onBlur={formState.OtherYear ? undefined : e => setIsSelected4(false)}
                                        onInput={e => setIsSelected4(true)}
                                        />
                                    </div>
                                )}

                                {/* Grad Semester */}
                                {isStudent() && (
                                    <div className="padded">
                                        <FormControl>
                                            <InputLabel id="year-label">Graduation Semester</InputLabel>
                                            <Select
                                                labelId="year-label"
                                                name="GradSemester"
                                                variant="outlined"
                                                value={formState?.GradSemester}
                                                label="Academic Year"
                                                onChange={updateFormControlSelect5}
                                                sx={{width: fieldWidth}}
                                            >
                                                <MenuItem value={"None"}>-</MenuItem>
                                                <MenuItem value={"Spring"}>Spring</MenuItem>
                                                <MenuItem value={"Fall"}>Fall</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}

                                {/* Grad Year */}
                                {isStudent() && (
                                    <div className="padded">
                                        <TextField 
                                            type="number"
                                            inputProps={{pattern: regex}}
                                            id="GradYear" 
                                            label="Graduation Year" 
                                            variant="outlined"
                                            helperText="Year must be >= 1000 and < 2100"
                                            onChange={updateFormControl}
                                            value={formState?.GradYear} 
                                            sx={{width: fieldWidth}}
                                            InputProps={userAdornment}
                                            onFocus={e => setIsSelected4(true)}
                                            onBlur={formState.GradYear ? undefined : e => setIsSelected4(false)}
                                            onInput={e => setIsSelected4(true)}
                                        />
                                    </div> 
                                )}
                                {isAlum() && (
                                    <div className="padded">
                                        <TextField 
                                            required
                                            type="number"
                                            inputProps={{pattern: regex}}
                                            id="GradYear" 
                                            label="Graduation Year" 
                                            helperText="Year must be >= 1000 and < 2100"
                                            variant="outlined"
                                            onChange={updateFormControl}
                                            value={formState?.GradYear} 
                                            sx={{width: fieldWidth}}
                                            InputProps={userAdornment}
                                            onFocus={e => setIsSelected4(true)}
                                            onBlur={formState.GradYear ? undefined : e => setIsSelected4(false)}
                                            onInput={e => setIsSelected4(true)}
                                        />
                                    </div> 
                                )}

                                {/* Rookie */}
                                {isStudent() && (
                                    <div className="padded">
                                        <FormControl>
                                        <InputLabel required id="year-label">Member Type</InputLabel>
                                        <Select
                                            required
                                            labelId="year-label"
                                            name="Rookie"
                                            variant="outlined"
                                            value={formState?.Rookie}
                                            label="Rookie?"
                                            onChange={updateFormControlSelect6}
                                            sx={{width: fieldWidth}}
                                        >
                                            <MenuItem value={true as any}>Rookie</MenuItem>
                                            <MenuItem value={false as any}>Returning</MenuItem>
                                        </Select>
                                        </FormControl>
                                    </div>
                                )}
                            </CardContent>
                        </form>
                    </Card>
                </Box>
            </div>
            <br />
            <button 
                className="main-button modal" 
                onClick={handleSave} 
                disabled={saving}
            >
                {saving ? 'Saving...' : 'Save'}
            </button>
            <button className="outline-button modal" onClick={handleCancel}>Cancel</button>
        </>
    );
};

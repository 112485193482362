import React, { 
    ChangeEvent, 
    FormEvent, 
    useEffect,
    useState 
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    InputLabel,
    InputAdornment,
    TextField,
} from '@mui/material';
import { updateInstruments } from '../../api/firebaseApi';
import { FcInfo } from 'react-icons/fc';
import { HiSearch } from 'react-icons/hi';
import { RiArrowDropDownLine } from 'react-icons/ri';
import '../../styles/forms.scss';

// Define screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
    tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
    medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
    large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
    xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
};

// Instrument list type
type FormStateInstrument = {
    instrument: string[];
};

// Initial instrument array
const initialInstrumentFormState = { instrument: [] };

// Define instrument list
const instrumentOptionsList = [
    'Flute',
    'Piccolo',
    'Oboe',
    'Clarinet',
    'Alto Saxophone',
    'Tenor Saxophone',
    'Bari Saxophone',
    'Trumpet',
    'Mellophone',
    'Baritone',
    'Trombone',
    'Tuba',
    'Bass Guitar',
    'Guitar',
    'Drum Set',
    'Snare Drum',
    'Tenor Drums',
    'Bass Drum',
    'Cymbals',
    'Keyboard',
    'Colorguard',
];

export function InstrumentEditForm(props: { alert: Function; accInstrument: string[]; closeHandler: Function; email: string; }) {

    // Get the component props and initialize useStates
    const { alert, accInstrument, closeHandler, email } = props;
    const [screenSize, setScreenSize] = useState(screens);
    const [searchWidth, setSearchWidth] = useState(400/1.07);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [instrumentOptions, setInstrumentsOptions] = useState(instrumentOptionsList);
    const [instrumentSearch, setInstrumentSearch] = useState('');
    const [formStateInst, setFormStateInst] = useState<FormStateInstrument>(initialInstrumentFormState);
    const [instrumentsList, setInstrumentsList] = useState(accInstrument);
    const [saving, setSaving] = useState<boolean>(false);

    // Function to show a message from form
    const showAlert = (alertPayload: object) => {
        alert(alertPayload);
    };

    const handleCancel = () => {
        closeHandler(false);
    };

    // useEffect for screen size changes
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
            tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
            medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
            large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
            xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSize}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, [screenSize]);

    // useEffect for changing state variables based on screen size
    useEffect(() => {
        if (screenSize.small) {
            setSearchWidth(250/1.18);
        } else if (screenSize.tabletPort) {
            setSearchWidth(350/1.07);
        } else if (screenSize.tabletLand) {
            setSearchWidth(400/1.07);
        } else if (screenSize.large) {
            setSearchWidth(500/1.07);
        } else if (screenSize.xlarge) {
            setSearchWidth(500/1.07);
        } else {
            setSearchWidth(400/1.2);
        }
    }, [screenSize]);

    const handleSave = async () => {
        setSaving(true);
        const sendUpdate = await updateInstruments(email, instrumentsList)
        if (!sendUpdate[0]) {
            // Show floating error alert message
            const message = {
                id: uuidv4(),
                type: 'error',
                message: `${sendUpdate[1]}`,
            };
            showAlert(message);
        } else {
            // Show floating success alert message
            const message = {
                id: uuidv4(),
                type: 'success',
                message: 'Success! Instruments updated.',
            };
            showAlert(message);
            handleCancel();
        }
        setSaving(false);
    };

    // Function to add instrument chip
    const addInstrument = (instrument: string) => {
        let exists = false;
        let addArray: string[] = [];
        for (var item of instrumentsList) {
            addArray.push(item);
            if (item === instrument) { 
                exists = true; 
            }
        }
    
        if (!exists) { 
            let letter = instrument.charAt(0).toUpperCase();
            let cut = instrument.substring(1).toLowerCase();
            let capInstrument = `${letter}${cut}`;
            addArray.push(capInstrument); 
        }
        setInstrumentsList(addArray);
    };
    
    // Handler to update for dropdown selection for instrument
    const updateFormControlSelect = (value: string, event: FormEvent) => {
        event.preventDefault();
        addInstrument(value);
    
        let newArray: string[] = [];
        for (let item of formStateInst.instrument) {
            newArray.push(item);
        }
        newArray.push(value);
        const updatedFormState = { ...formStateInst };
        updatedFormState['instrument'] = newArray;
        setFormStateInst(updatedFormState);
    };

    // Function to filter instrument options
    const filterInstrumentList = (filterOn: string) => {
        if (filterOn === '') {
            setInstrumentsOptions(instrumentOptionsList)
        } else { 
            const filteredInstruments: string[] = instrumentOptions.filter((instrument) =>
                instrument.toLowerCase().includes(filterOn.toLowerCase())
            );
            setInstrumentsOptions(filteredInstruments)
        }
    };

    // Update form text field
    const updateFormControl = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        setInstrumentSearch(value);
        filterInstrumentList(value);
    };

    // Function to delete an instrument chip
    const handleDeleteChip = (instrumentToDelete: string) => {
        const updatedInstruments = instrumentsList.filter((instrument) => instrument !== instrumentToDelete);
        setInstrumentsList(updatedInstruments);
        if (updatedInstruments.length === 0) { 
            // Show floating error alert message
            const message = {
                id: uuidv4(),
                type: 'error',
                message: 'You must add at least one instrument.',
            };
            showAlert(message);
        }
    };

    // Searchbox adornment
    const searchAdornment = isSelected
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <HiSearch style={{fontSize: "110%"}}/>
            </InputAdornment>
        )
    }
    : {};

    return (
        <>
            <h2>Edit Instuments</h2>
            <div className="padded">
                <InputLabel required className="instrument-label">Instrument(s)</InputLabel>
                <div className="instruments-div">
                    {instrumentsList.length === 0 && (
                        <Chip 
                            className="MuiChip-root info"
                            icon={<FcInfo style={{transform: 'translateY(-2px)'}} />} 
                            label="Please select the instrument(s) that you currently play." 
                            variant="outlined"
                        />
                    )}
                    {instrumentsList.map((instrument: string) => {
                        return (
                            <div style={{padding: '5px 0 0 5px'}}>
                                <Chip 
                                    key={`${instrument}-chip`}
                                    label={instrument}
                                    variant="outlined"
                                    onDelete={() => handleDeleteChip(instrument)}
                                    color="secondary"
                                />
                            </div>
                        )
                    })}
                </div>
                <Accordion>
                    <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
                        <span>Instrument Options</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{marginTop: 15}}>
                            <TextField 
                                id="instrumentSearch" 
                                helperText="Search for an instrument here. Click an instrument to add it to your profile."
                                label="Search" 
                                variant="outlined"
                                value={instrumentSearch}
                                onChange={updateFormControl}
                                InputProps={searchAdornment}
                                sx={{width: searchWidth}}
                                onFocus={e => setIsSelected(true)}
                                onBlur={instrumentSearch ? undefined : e => setIsSelected(false)}
                                onInput={e => setIsSelected(true)}
                            />
                        </div>

                        {instrumentOptions.length === 0 && (
                            <div className="instrument-button-div">
                                <button 
                                    className="instrument-button"
                                    onClick={(event) => updateFormControlSelect(instrumentSearch, event)}
                                >
                                    ADD '{instrumentSearch.toUpperCase()}'
                                </button>
                            </div>
                        )}
                        
                        {instrumentOptions.map((instrument, key) => {
                            return (
                                <div key={`${key}-${instrument}`} className="instrument-button-div">
                                    <button 
                                        className="instrument-button"
                                        onClick={(event) => updateFormControlSelect(instrument, event)}
                                    >
                                        {instrument.toUpperCase()}
                                    </button>
                                </div>
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
            </div>
            <br />
            <button 
                className="main-button" 
                onClick={handleSave} 
                disabled={instrumentsList.length === 0 || saving}
            >
                {saving ? 'Saving...' : 'Save'}
            </button>
            <button className="outline-button modal" onClick={handleCancel}>Cancel</button>
        </>
    );
};

import React, { useState } from 'react';
import { AdminToolTabs } from '../TabNavigation/AdminToolTabs';
import { AdminToolTabPanels } from '../TabNavigation/AdminToolTabPanels';
import { EmailVerificationTable } from '../AdminTools/EmailVerificationTable';
import { ManageRosters } from '../AdminTools/ManageRosters.tsx';
import { ManageUsers } from '../AdminTools/ManageUsers.jsx';

export function AdminToolsDashboard(props) {
    const { alert } = props;
    const [tabValue, setTabValue] = useState(0);
  
    const handleChange = (event, tab) => {
        event.preventDefault();
        setTabValue(tab);
    };

    const alertHandler = (payload) => {
        alert(payload);
    };

    return (
        <div id="admin-tools-dashboard">
            <AdminToolTabs value={tabValue} handleChange={handleChange}>
                <AdminToolTabPanels value={tabValue} index={0}>
                    <ManageRosters alert={alertHandler} />
                </AdminToolTabPanels>
                <AdminToolTabPanels value={tabValue} index={1}>
                    <EmailVerificationTable alert={alertHandler} />
                </AdminToolTabPanels>
                <AdminToolTabPanels value={tabValue} index={2}>
                    <ManageUsers alert={alertHandler} />
                </AdminToolTabPanels>
            </AdminToolTabs>
        </div>
    )
}

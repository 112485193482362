import React from 'react';
import pfp from '../../assets/image-placeholder.jpeg';
import { AiFillHome } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { sendLogOut } from '../../api/firebaseApi';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import '../../pages/Dashboard/Dashboard.scss';

export function AdminDashboardHeader(props) {
    const {
        screens, 
        user, 
        title, 
        alert,  
        account, 
        sideBarOpen,
    } = props;

    const localAcc = JSON.parse(localStorage.getItem('account')) 
        ? JSON.parse(localStorage.getItem('account')) 
        : {FirstName: '', LastName: ''}
    ;
    const name = `${account.FirstName ? account.FirstName : localAcc.FirstName} ${account.LastName ? account.LastName : localAcc.LastName}`

    // Function to handle log out
    const logOutHandler = async () => {
        const loggedOut = await sendLogOut();
        if (loggedOut[0]) {
            const cookieAgreement = localStorage.getItem('cookieAgree');
            localStorage.clear();
            localStorage.setItem('cookieAgree', cookieAgreement);
            const alertPayload = {
                id: uuidv4(),
                type: 'success',
                message: 'You have been successfully logged out.',
            };
            alert(alertPayload);
        } else {
            const alertPayload = {
                id: uuidv4(),
                type: 'error',
                message: `${loggedOut[1]}`,
            };
            alert(alertPayload);
        }
    };

    return (
        <>
            <div id="admin-dashboard-header-wrapper">
                {(!screens.tablet && !screens.small) && (
                    <div id="profile-wrapper" className="profile-wrapper">
                            <img src={pfp} alt="pfp.png" width="25px" />
                            {!!user
                                ? <div>
                                    <h1 style={screens.tabletPort ? {fontSize: 16} : {}}>{name}</h1>
                                    {account.Student && (
                                        <p>
                                            Student • {!!account.Instrument  
                                                ? account.Instrument[0] 
                                                : localAcc.Instrument[0]
                                            }
                                        </p>
                                    )}
                                    {account?.Staff && (
                                        <p>
                                            Staff • {!!account.Instrument 
                                                ? account.Instrument[0] 
                                                : localAcc.Instrument[0]
                                            }
                                        </p>
                                    )}
                                </div>
                                : <h1>
                                    <Link to="/login">Log In</Link>
                                    <span> / </span>
                                    <Link to="/create-account">Create</Link>
                                </h1>
                            }
                    </div>
                )}
                <div 
                    id="admin-dashboard-title-wrapper" 
                    className="dashboard-title" 
                    style={
                        !screens.small 
                        ? { width: sideBarOpen ? '41%' : '49%' } 
                        : {}
                    }
                >
                    <h1>{title}</h1>
                </div>
                {!screens.small && (
                    <div id="admin-dashboard-actions" className="dashboard-actions">
                        {true
                            ? (
                                <>
                                    <Link to="/admin/dashboard">
                                        <button className="main-button">
                                            <AiFillHome style={{transform: 'translateY(1px)'}} /> {screens.tabletPort ? 'Admin' : 'Admin Home'}
                                        </button>
                                    </Link>
                                    <Link to="/dashboard">
                                        <button className="main-button">
                                            <MdOutlineSpaceDashboard /> {screens.tabletPort ? 'Dashboard' : 'Dashboard Home'}
                                        </button>
                                    </Link>
                                    <button className="main-button" onClick={logOutHandler}>
                                        <BiLogOut /> Log Out
                                    </button>
                                </>
                            )
                            : <div style={{width: '21.75em'}} />
                        }
                    </div>
                )}
            </div>
            {(screens.small && !!user) && (
                <div id="admin-dashboard-actions" className="dashboard-actions">
                    <Link to="/admin/dashboard">
                        <button className="main-button">
                            <AiFillHome /> Admin
                        </button>
                    </Link>
                    <Link to="/dashboard">
                        <button className="main-button">
                            <MdOutlineSpaceDashboard /> Dashboard
                        </button>
                    </Link>
                    <button className="main-button" onClick={logOutHandler}>
                        <BiLogOut /> Log Out
                    </button>
                </div>
            )}
        </>
    );
};

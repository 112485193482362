import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { InterestForm } from '../../components/Forms/InterestForm.tsx';
import join from '../../assets/join.JPG';
import { Link } from 'react-router-dom';
import './Auditions.scss';

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/auditions.jpg'); 
        background-position: bottom; 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

// Page to display info about auditions
export function Auditions(props) {
    // Get the component props
    const { alert, sideBarOpen } = props;
    const formRef = useRef(null);
    const urlParams = new URLSearchParams(window.location.search);
    const target = urlParams.get('target');
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSize}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, [screenSize]);

    // Function to show a message from audition form
    const showAlert = (alertPayload) => {
        alert(alertPayload);
    };

    const scrollToForm = () => {
        setTimeout(() => formRef.current.scrollIntoView({ inline: 'center', behavior: 'smooth' }), 100);
    };

    useEffect(() => {
        if(target === 'interest-form') {
            scrollToForm();
        }
    }, [target])

    return (
        <section id="auditions-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Auditions</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="auditions-title-div" className="auditions-title">
                <h1>Join Chucktown Sound!</h1>
                <h2>Schedule Your Audition Today!</h2>
            </div>
            <div id="auditions-wrapper-div" className="auditions-wrapper-div">
                <div id="auditions-content" className="auditions-content">
                    <div id="audition-info">
                        <div className="auditions-content-split">
                            <div className="split-left">
                                <h2>Join Our Family!</h2>
                                <p>
                                    Chucktown Sound is more than just an Athletic Band; it's a family. We provide the perfect environment for our members to create lasting memories and friendships. Our social committee plans social events outside of rehearsals and performances for members to further get to know each other. So, what are you waiting for? Submit your application today and join the Chucktown Sound Family!
                                </p>
                                <h2>The Audition Process</h2>
                                <p>
                                    First, please submit an <Link to="/auditions?target=interest-form" onClick={scrollToForm}>interest form</Link> in the section below. Next, review the audition requirements and procedures by clicking the buttons below. Lastly, email your audition video to our director, Nick Nafpliotis, at <a href="mailto:nnafplio@cofc.edu">nnafplio@cofc.edu</a>. You will then recieve an email from our director to return your auditon results. Please email Nick Nafpliotis (<a href="mailto:nnafplio@cofc.edu">nnafplio@cofc.edu</a>.) if you have any questions, comments, or concerns about the process.
                                </p>
                            </div>
                            <div className="split-right">
                                <img id="join-img" src={join} alt="join.JPG" />
                            </div>
                        </div>
                    </div>

                    <div id="audition-buttons">
                        <h2>Audition Materials</h2>
                        <Link 
                            to="https://docs.google.com/document/d/1TGm_9wzfwY5n0DifIth0hcLvKGm2ljpUXn9K3m-zQLk/edit?usp=drive_link"
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <button className="main-large">Brass & Woodwinds</button>
                        </Link>
                        <Link 
                            to="https://drive.google.com/file/d/1V0MZcL1vou5hST9b0FxGJLuBMDUU5tOH/view?usp=drive_link" 
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <button className="main-large">Percussion/Drumline</button>
                        </Link>
                        <Link 
                            to="https://docs.google.com/document/d/1iDoo1iIzMtShjjP0VIAeHZPHsJIIj7DcHWVNxM-eR6Y/edit?usp=sharing" 
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <button className="main-large">All Other Instruments</button>
                        </Link>
                    </div>

                    <div id="interest-form" ref={formRef} className="auditions-content-center">
                        <InterestForm alert={showAlert} />
                    </div>
                </div>
            </div>
        </section>
    );
};

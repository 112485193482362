import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSingleAttendanceForm, getSubmissionCodeByUuid } from '../../api/firebaseApi';
import { v4 as uuidv4 } from 'uuid';
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    FormControlLabel,
    Radio, 
    RadioGroup,
} from '@mui/material';
import { AiFillEye, AiFillEyeInvisible, AiOutlineReload } from 'react-icons/ai';
import { FcInfo } from 'react-icons/fc';
import  { RiArrowDropDownLine } from 'react-icons/ri';
import { Chip } from '@mui/material';
import { DataTablePagination } from '../TablePagination/DataTablePagination';
import { Oval } from 'react-loading-icons';
import Countdown from '../Utils/Countdown';
import './AdminViewAttendanceForm.scss';
import { generateAttendancePdf } from '../Utils/ExportPDF';
import { AttendanceCodeModal } from '../Modals/AttendanceCodeModal';

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function AdminViewAttendanceForm(props) {
    const { alert, uuid } = props;
    const initialCode = 123456;
    const [screenSize, setScreenSize] = useState(screens);
    const [formData, setFormData] = useState({
        CreatedDate: '',
        Title: '',
        ExpirationDate: '',
        Submissions: [{}],
        SubmissionCode: 0,
        Status: false,
        CreatedBy: '',
        StartDate: '',
    });
    // TODO: Set up table sorting
    // eslint-disable-next-line no-unused-vars
    const [formSortBy, setFormSortBy] = useState('LastName');
    // TODO: Set up table sorting
    // eslint-disable-next-line no-unused-vars
    const [rosterSortBy, setRosterSortBy] = useState('LastName');
    const [showCode, setShowCode] = useState(false);
    const [decryptedCode, setDecryptedCode] = useState(initialCode);
    const [formDataLength, setFormDataLength] = useState(0);
    // TODO: Set up table sorting
    // eslint-disable-next-line no-unused-vars
    const [rosterLength, setRosterLength] = useState(0);
    const [rowsPerPageFormData, setRowsPerPageFormData] = useState(5);
    const [pageFormData, setPageFormData] = useState(0);
    const [rowsPerPageRoster, setRowsPerPageRoster] = useState(5);
    const [pageRoster, setPageRoster] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCode, setIsLoadingCode] = useState(false);
    const [isRefreshLoading, setIsRefreshLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [pdfOption, setPdfOption] = useState('submissions');
    const [isRotatable, setIsRotatable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const startIndexFormData = pageFormData * rowsPerPageFormData;
    const endIndexFormData = startIndexFormData + rowsPerPageFormData;
    const startIndexRoster = pageRoster * rowsPerPageRoster;
    const endIndexRoster = startIndexRoster + rowsPerPageRoster;

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    useEffect(() => {
        if (screenSize.small) {
            setIsRotatable(false);
        } else if (screenSize.tabletPort) {
            setIsRotatable(true);
        } else if (screenSize.tabletLand) {
            setIsRotatable(true);
        } else if (screenSize.large) {
            setIsRotatable(false);
        } else if (screenSize.xlarge) {
            setIsRotatable(false);
        } else {
            setIsRotatable(false);
        }
    }, [screenSize]);

    const handleRowsChangeFormData = (event) => {
        const { value } = event.target;
        setRowsPerPageFormData(parseInt(value));
    };

    const handlePageChangeFormData = (_, newPage) => {
        setPageFormData(newPage);
    };

    const emptyRowsFormData = (pageFormData > 0 || formDataLength < 5)
        ? Math.max(0, (1 + pageFormData) * rowsPerPageFormData - formDataLength) 
        : 0
    ;

    const handleRowsChangeRoster = (event) => {
        const { value } = event.target;
        setRowsPerPageRoster(parseInt(value));
    };

    const handlePageChangeRoster = (_, newPage) => {
        setPageRoster(newPage);
    };

    const emptyRowsRoster = (pageRoster > 0 || rosterLength < 5)
        ? Math.max(0, (1 + pageRoster) * rowsPerPageRoster - rosterLength) 
        : 0
    ;

    const pdfOptionChangeHandler = (event) => {
        const { value } = event.target;
        if (value === 'comparison' && rosterLength === 0) {
            const payload = {
                id: uuidv4(),
                type: 'info',
                message: 'Current roster is empty. You may need to add users to the current roster.'
            }
            alertHandler(payload);
        }
        setPdfOption(value);
    };

    const alertHandler = useCallback((payload) => {
        alert(payload);
    }, [alert]);

    const refreshHandler = async () => {
        setIsRefreshLoading(true);
        const form = await getSingleAttendanceForm(uuid, formSortBy, rosterSortBy);
        if (form[0]) {
            setFormDataLength(form[1].Submissions.length);
            setRosterLength(form[1].ComparedRoster.length);
            setFormData(form[1]);
        } else {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: form[1],
            }
            alertHandler(payload);
        }
        setIsRefreshLoading(false);
    };

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const form = await getSingleAttendanceForm(uuid, formSortBy, rosterSortBy);
        if (form[0]) {
            setFormDataLength(form[1].Submissions.length);
            setRosterLength(form[1].ComparedRoster.length);
            setFormData(form[1]);
        } else {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: form[1],
            }
            alertHandler(payload);
        }
        setIsLoading(false);
    }, [alertHandler, uuid, formSortBy, rosterSortBy]);

    useEffect(() => {
        if (formData.Title === '') {
            fetchData();
        }
    }, [alertHandler, fetchData, formData.Title]);

    const showCodeHandler = async (reload) => {
        setIsLoadingCode(true);
        if (!showCode || reload) {
            const code = await getSubmissionCodeByUuid(uuid);
            if (code[0]) {
                setDecryptedCode(code[1]);
            } else if (!code[0]) {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: code[1],
                };
                alertHandler(payload);
            }
        } else {
            setDecryptedCode(initialCode);
        }
        setShowCode(!showCode);
        setIsLoadingCode(false);
    };

    const modalHandler = async () => {
        setIsModalOpen(!isModalOpen);
        await showCodeHandler(true);
    };

    const generatePDFHandler = () => {
        setIsExporting(true);
        const response = generateAttendancePdf(pdfOption, formData);
        const payload = {
            id: uuidv4(),
            type: '',
            message: '',
        }
        if (response[0]) {
            payload.type = 'success';
            payload.message = response[1];
        } else {
            payload.type = 'error';
            payload.message = response[1];
        }
        alertHandler(payload);
        setIsExporting(false);
    };

    return (
        <div id="view-form" className="view-form">
            <h1 id="form-title">Attendance Form: {formData.Title}</h1>
            <div id="view-form-content">
                <Accordion style={{width: 'fit-content'}}>
                    <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
                        Form Details
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="details">
                            <p><strong>Title:</strong> {formData.Title}</p>
                            <p><strong>Form UUID:</strong> {uuid}</p>
                            <p><strong>Created By:</strong> {formData.CreatedBy} <strong>on</strong> {formData.CreationDate}</p>
                            <p><strong>Start Date:</strong> {formData.StartDate}</p>
                            <p><strong>Expiration Date:</strong> {formData.ExpirationDate}</p>
                            <p><strong>Current Status:</strong> {formData.Status ? 'Open' : 'Closed'}</p>
                            <p><strong>Submission Code:</strong> {showCode 
                                    ? <>
                                        {decryptedCode} <AiFillEyeInvisible onClick={showCodeHandler} />
                                    </> 
                                    : <>
                                        {isLoadingCode && (
                                            <Oval className="loading-code" stroke="#111111" />
                                        )}
                                        <AiFillEye onClick={showCodeHandler} />
                                    </>
                                }
                            </p>
                            {isRotatable && (
                                <p>
                                    <button className="main-button details" onClick={modalHandler}>
                                        Show Full Screen Code
                                    </button>
                                </p>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <h3>{formData.Title} Submissions</h3>
            <div id="refresh-table">
                <p>Refreshing in&nbsp;
                    <Countdown onTimerComplete={refreshHandler} seconds={10} />
                    &nbsp;{isRefreshLoading 
                        ? <Oval className="refresh-oval" /> 
                        : <AiOutlineReload className="reload" onClick={refreshHandler} />
                    }
                </p>
            </div>
            <div className="table-div">  
                <table id="submissions-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Last Name</th>
                            <th>First Name</th>
                            <th>Submission UUID</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formDataLength > 0 && ((rowsPerPageFormData > 0
                                ? formData.Submissions.slice(startIndexFormData, endIndexFormData)
                                : formData.Submissions
                            ).map((row, key, index) => {
                            return (
                                <tr key={`${key}-${row.key}`}>
                                    <td>{index.indexOf(row) + 1}</td>
                                    <td>{row.data.LastName}</td>
                                    <td>{row.data.FirstName}</td>
                                    <td>{row.key}</td>
                                    <td>{row.data.Timestamp}</td>
                                </tr>
                            )
                        }))}
                        {(formDataLength !== 0 && emptyRowsFormData > 0) && (
                            <tr style={{ height: 25.5 * emptyRowsFormData }}>
                                <td colSpan={6} aria-hidden />
                            </tr>
                        )}
                        {formDataLength === 0 && (
                            <tr>                                
                                {isLoading 
                                    ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                    : <td colSpan={6}>
                                        <Chip 
                                            className="MuiChip-root MuiChip-root info-table"
                                            icon={<FcInfo />} 
                                            label="No Submissions to Display" 
                                            variant="outlined" 
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
                {formDataLength > 0 && (
                    <DataTablePagination
                        length={formDataLength}
                        rowsPerPage={rowsPerPageFormData}
                        page={pageFormData}
                        handlePageChange={handlePageChangeFormData}
                        handleRowsPerPageChange={handleRowsChangeFormData}
                    />
                )}

                {pdfOption === 'comparison' && (
                    <div className="table-div">
                        <h3 id="roster-title">Roster Comparison</h3>
                        <div className="color-key-div">
                            <div className="color-square green" /><p style={{marginRight: '1.5em'}}>Submitted</p>
                            <div className="color-square red" /><p>Not Submitted</p>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rosterLength > 0 && ((rowsPerPageRoster > 0
                                    ? formData.ComparedRoster.slice(startIndexRoster, endIndexRoster)
                                    : formData.ComparedRoster
                                ).map((row, key, index) => {
                                    return (
                                        <tr key={`${key}-${row.key}`}>
                                            <td className={row.Submitted ? 'green' : 'red'}>{index.indexOf(row) + 1}</td>
                                            <td className={row.Submitted ? 'green' : 'red'}>{row.LastName}</td>
                                            <td className={row.Submitted ? 'green' : 'red'}>{row.FirstName}</td>
                                        </tr>
                                    )
                                }))}
                                {(rosterLength !== 0 && emptyRowsRoster > 0) && (
                                    <tr style={{ height: 25.5 * emptyRowsRoster }}>
                                        <td colSpan={6} aria-hidden />
                                    </tr>
                                )}
                                {rosterLength === 0 && (
                                    <tr>                                
                                        {isLoading 
                                            ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                            : <td colSpan={6}>
                                                <Chip 
                                                    className="MuiChip-root MuiChip-root info-table"
                                                    icon={<FcInfo />} 
                                                    label="No Roster to Display" 
                                                    variant="outlined" 
                                                />
                                            </td>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {rosterLength > 0 && (
                            <DataTablePagination
                                length={rosterLength}
                                rowsPerPage={rowsPerPageRoster}
                                page={pageRoster}
                                handlePageChange={handlePageChangeRoster}
                                handleRowsPerPageChange={handleRowsChangeRoster}
                            />
                        )}
                    </div>
                )}

                <div className="page-actions">
                    <div>
                        <Link to="/admin/dashboard/attendance">
                            <button className="main-button" style={{marginLeft: 0}}>Back</button>
                        </Link>
                    </div>
                    <RadioGroup
                        row
                        name="radio-buttons-group" 
                        defaultValue="submissions"
                        value={pdfOption}
                        onChange={(e) => pdfOptionChangeHandler(e)}
                    >
                        <FormControlLabel 
                            value="submissions" 
                            control={<Radio />} 
                            label="Submissions Only" 
                        />
                        <FormControlLabel 
                            value="comparison" 
                            control={<Radio />} 
                            label="With Roster Comparison" 
                        />
                        {isExporting 
                            ? <Oval className="refresh-oval" /> 
                            : <button 
                                className="main-button"
                                style={{marginRight: 0}}
                                onClick={generatePDFHandler} 
                                disabled={formDataLength === 0}
                            >
                                Export PDF
                            </button>
                        }
                    </RadioGroup>
                </div>
            </div>
            {isModalOpen && (
                <AttendanceCodeModal closeHandler={modalHandler} code={decryptedCode} />
            )}
        </div>
    )
}

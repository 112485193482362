import React, { useEffect, useState, FormEvent } from 'react';
import { 
    Box, 
    Card, 
    CardContent,
    FormHelperText, 
    CardHeader 
} from '@mui/material';
import { AlertBanner } from '../Alerts/AlertBanner';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { v4 as uuidv4 } from 'uuid';
import { updateAttendanceForm, updateFoodOrderForm } from '../../api/firebaseApi';
import '../../styles/forms.scss';
import { determineDST } from '../Utils/Timer';

// Define alternate screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
};

// Define form state types
type FormState = {
    StartDate: null | Dayjs;
    ExpirationDate: null | Dayjs;
    FormUUID: string;
};

type Configs = { action: string, type: string }

type Props = { 
    alert: Function; 
    close: Function; 
    refresh: Function;
    formData: FormState;
    configs: Configs;
};

const determineEnd = (type: string) => type === 'attendance' ? dayjs().add(3, 'hour') : dayjs().add(7, 'day')

export function UpdateFromTimesForm(props: Props) {
    // Set states
    const { alert, close, refresh, formData, configs } = props;
    const { action, type } = configs;
    const [formState, setFormState] = useState<FormState>({
        ...formData,
        StartDate: action === 'end' ? dayjs(formData.StartDate) : dayjs(),
        ExpirationDate: action === 'end' ? dayjs() : determineEnd(type),
    });
    const [errorDates, setErrorDates] = useState<boolean>(false);
    const [datesEqual, setDatesEqual] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [screenSize, setScreenSize] = useState(screens);
    
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    const alertHandler = (payload: object) => {
        alert(payload);
    };

    // Check dates
    const checkDates = (start: Dayjs | null, end: Dayjs | null) => {
        if (start !== null && end !== null) {
            const startDate = new Date(start.toISOString());
            const endDate = new Date(end.toISOString());
            const comp =  startDate > endDate;
            const eq = startDate.toUTCString() === endDate.toUTCString();

            if (comp) {
                setErrorDates(true);
                return false;
            } else if (eq) {
                setDatesEqual(true);
                return false;
            } else {
                setDatesEqual(false);
                setErrorDates(false);
                return true;
            }
        }
        return false;
    };

    // Form submission function
    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        const datesCheck = checkDates(formState.StartDate, formState.ExpirationDate);
        if (datesCheck) {
            setSubmitting(true);
            const formPayload = { 
                StartDate: determineDST(formState.StartDate.format('MM/DD/YYYY h:mm A')),
                ExpirationDate: determineDST(formState.ExpirationDate.format('MM/DD/YYYY h:mm A')),
            };
            let response:any;
            if (type === 'attendance') {
                response = await updateAttendanceForm(formState.FormUUID, formPayload);
            } else if (type === 'food-order') {
                response = await updateFoodOrderForm(formState.FormUUID, formPayload);
            }
            
            if (response[0]) {
                const payload = [
                    {
                        id: uuidv4(),
                        type: 'info',
                        message: 'It may take up to one minute for the form status to update.',
                    },
                    {
                        id: uuidv4(),
                        type: 'success',
                        message: 'Form updated successfully',
                    },
                ];
                alertHandler(payload);
                refresh();
                closeModalHandler();
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: response[1],
                }
                alertHandler(payload);
            }
            setSubmitting(false);
        } else {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: 'One or more fields are empty.',
            }
            alertHandler(payload);
        }
    };

    const closeModalHandler = () => {
        close();
    };

    const closeAlertHandler = (banner: string) => {
        if (banner === 'dates') {
            setErrorDates(false);
        } else if (banner === 'dates-equal') {
            setDatesEqual(false);
        }
    }

    // Form controller start date
    const updateFormControlStartDate = (newValue: Dayjs | null) => {
        const updatedFormState = { ...formState };
        if (newValue === null) { 
            newValue = dayjs(); 
        }
        updatedFormState.StartDate = newValue;
        setFormState(updatedFormState);
    };

    // Form controller expiration date
    const updateFormControlExpirationDate = (newValue: Dayjs | null) => {
        const updatedFormState = { ...formState };
        if (newValue === null) { 
            newValue = dayjs().add(24, 'hour'); 
        }
        updatedFormState.ExpirationDate = newValue;
        setFormState(updatedFormState);
    };

    const capitalizeFirstLetter = (input:string) => input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();

    const formatTitle = (action:string, type:string) => {
        const actionString = capitalizeFirstLetter(action);
        if (type === 'food-order') {
            return actionString + ' Food Order';
        }
        return actionString + ' Attendance';
    };

    return (
        <>
            <div id={`update-${type}-dates-form`}>
                <Box sx={{ display: 'inline-block'}}>
                    <Card>
                        <form>
                            <CardHeader title={`${formatTitle(action, type)} Form`} />
                            <CardContent>

                                {/* Date errors */}
                                {errorDates && (
                                    <div className="padded">
                                        <AlertBanner type="error" message="Start date must be BEFORE end date." close={() => closeAlertHandler('dates')} />
                                    </div>
                                )}

                                {datesEqual && (
                                    <div className="padded">
                                        <AlertBanner type="error" message="Start date and end date cannot be the same." close={() => closeAlertHandler('dates-equal')} />
                                    </div>
                                )}

                                {/* Begin Date */}
                                <div className="padded">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker 
                                            label="Choose a start date/time" 
                                            value={formState.StartDate}
                                            onChange={(newValue) => updateFormControlStartDate(newValue)}
                                            disabled={action === 'end'}
                                        />
                                    </LocalizationProvider>
                                </div>

                                {/* Expiration Date */}
                                <div className="padded">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker 
                                            label="Choose an end date/time" 
                                            value={formState.ExpirationDate}
                                            onChange={(newValue) => updateFormControlExpirationDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>

                                {/* Form UUID */}
                                <div className="padded">
                                    {screenSize.small && (
                                        <FormHelperText>
                                            Form ID:
                                        </FormHelperText> 
                                    )}
                                    <FormHelperText>
                                        {!screenSize.small && <>Form ID:&nbsp;</>}
                                        <span className="font-bg1">{formState.FormUUID}</span>
                                    </FormHelperText> 
                                </div>
                            </CardContent>
                        </form>
                    </Card>
                </Box>
            </div>
            <br />
            <button 
                className="main-button modal" 
                disabled={submitting}
                onClick={submitForm}
            >
                {submitting ? 'Updating...' : 'Update'}
            </button>
            <button className="outline-button modal" onClick={closeModalHandler}>Cancel</button>
        </>
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { Chip } from '@mui/material';
import { getAllAvailableFoodOrderForms } from '../../api/firebaseApi';
import { Oval } from 'react-loading-icons';
import { AiOutlineReload } from 'react-icons/ai';
import { FcInfo } from 'react-icons/fc';
import { DataTablePagination } from '../TablePagination/DataTablePagination';
import Countdown from '../Utils/Countdown';
import { SignFormModal } from '../Modals/SignFormModal';
import './PlaceFoodOrderForm.scss';

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function PlaceFoodOrderForm(props) {
    const { alert, account } = props;
    const [foodOrderForms, setFoodOrderForms] = useState([]);
    const [currentData, setCurrentData] = useState({uuid: '', title: ''});
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshLoading, setIsRefreshLoading] = useState(false);
    const [length, setLength] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [isSignModalOpen, setIsSignModalOpen] = useState(false);
    const [screenSize, setScreenSize] = useState(screens);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    // Determines number of empty data rows
    const emptyRows = page >= 0 
        ? Math.max(0, (1 + page) * rowsPerPage - length) 
        : 0
    ;

    // Table pagination page change handler
    const handlePageChange = (event, newPage) => {
        event.preventDefault();
        setPage(newPage);
    };

    // Table pagination rows per page change handler
    const handleRowsPerPageChange = (event) => {
        const { value } = event.target;
        setRowsPerPage(parseInt(value, 10));
        setPage(0);
    };

    const alertHandler = (payload) => {
        alert(payload);
    };

    // Function to maunally refresh data table 
    const refreshHandler = async () => {
        setIsRefreshLoading(true);
        const foodOrderFormData = await getAllAvailableFoodOrderForms(account.Email);
        if (foodOrderFormData[0]) {
            setLength(foodOrderFormData[1].length);
            setFoodOrderForms(foodOrderFormData[1]);
        } else {
            const errorPayload = {
                id: uuidv4(),
                type: 'error',
                message: foodOrderFormData[1],
            }
            alert(errorPayload);
        }
        setIsRefreshLoading(false);
    };

    const getForms = useCallback(async () => {
        if (length === 0) {
            setIsLoading(true);
            const foodOrderFormData = await getAllAvailableFoodOrderForms(account.Email);
            if (foodOrderFormData[0]) {
                setLength(foodOrderFormData[1].length);
                setFoodOrderForms(foodOrderFormData[1]);
            } else {
                const errorPayload = {
                    id: uuidv4(),
                    type: 'error',
                    message: foodOrderFormData[1],
                }
                alert(errorPayload);
            }
            setIsLoading(false);
        }
    }, [alert, length, account.Email]);

    useEffect(() => {
        getForms();
    }, [getForms]);

    const signModalOpenHandler = (uuid, title, options) => {
        setCurrentData({uuid, title, options});
        setIsSignModalOpen(true);
    };

    const signModalCloseHandler = () => {
        setIsSignModalOpen(false);
        setCurrentData({uuid: '', title: ''});
    };

    return (
        <div id="sign-food-order-wrapper">
            <h1>Place Food Order</h1>
            <div className="chip-div">
                <Chip 
                    className="MuiChip-root info white"
                    icon={<FcInfo style={{transform: 'translateY(-2px)'}} />} 
                    label={
                        <h4>
                            Select a food order from the table below. Only OPEN forms and forms that you have NOT yet signed will appear here.
                        </h4>
                    } 
                    variant="outlined" 
                />
            </div>
            <div id="refresh-table">
                <p>Refreshing in&nbsp;
                    <Countdown onTimerComplete={refreshHandler} seconds={10} />
                    &nbsp;{isRefreshLoading 
                        ? <Oval className="refresh-oval" /> 
                        : <AiOutlineReload className="reload" onClick={refreshHandler} />
                    }
                </p>
            </div>
            <div id='ord-data-table-wrapper'>
                <table id='food-order-table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Expiration Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {length !== 0 && ((rowsPerPage > 0
                                ? foodOrderForms.slice(startIndex, endIndex)
                                : foodOrderForms
                            ).map((row, key) => {
                                return (
                                    <tr key={`${key}-${row.key}`}>
                                        <td>{key + 1}</td>
                                        <td>{row.Title}</td>
                                        <td>
                                            {!!row.ExpirationDate 
                                                ? row.ExpirationDate.substring(0, row.ExpirationDate.length - 4) 
                                                : 'N/A'
                                            }
                                        </td>
                                        <td>
                                            <button 
                                                className={`main-button${screenSize.small ? ' table-button' : ''}`}
                                                onClick={() => signModalOpenHandler(row.key, row.Title, row.Options)}
                                            >
                                                {screenSize.small ? 'Order' : 'Place Order'}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                        {(length !== 0 && emptyRows > 0) && (
                            <tr style={{ height: 52.5 * emptyRows }}>
                                <td colSpan={6} aria-hidden />
                            </tr>
                        )}
                        {length === 0 && (
                            <tr>                                
                                {isLoading 
                                    ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                    : <td colSpan={6}>
                                        <Chip 
                                            className="MuiChip-root MuiChip-root info-table"
                                            icon={<FcInfo />} 
                                            label="No Forms to Display" 
                                            variant="outlined" 
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
                {length > 0 && (
                    <DataTablePagination
                        length={length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                    />
                )}
            </div>
            <div className="back-button-div">
                <Link to="/dashboard">
                    <button className="main-button">Back</button>
                </Link>  
            </div>  

            {isSignModalOpen && (
                <SignFormModal
                    alert={alertHandler}
                    closeHandler={signModalCloseHandler}
                    refresh={refreshHandler}
                    uuid={currentData.uuid} 
                    title={currentData.title} 
                    options={currentData.options}
                    userData={account}
                    type="food-order"
                />
            )}  
        </div>
    )
}

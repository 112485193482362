import React, { useEffect, useState, useCallback } from 'react';
import { getQRCodeLink, getMainLink, getSnapRaiseLink } from '../../api/firebaseApi';
import { Helmet } from 'react-helmet';
import join from '../../assets/donate.jpeg';
import { Link } from 'react-router-dom';
import './Donate.scss';

let redirect = false;

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/donate.jpg'); 
        background-position: bottom; 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export default function Donate(props) {

    const { sideBarOpen } = props;
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    const [qrLink, setQRLink] = useState(undefined);
    const [mainLink, setMainLink] = useState(undefined);
    const [snapLink, setSnapLink] = useState(undefined);
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSize}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, [screenSize]);
    
    if (!redirect && action === 'qr-scan') {
        if (typeof qrLink === 'string') {
            redirect = true;
            window.open(`${qrLink}`, '_blank');
        }
    }

    const getQRLink = useCallback( async () => {
        const res = await getQRCodeLink();
        if (res[0]) {
            setQRLink(res[1]);
        } else {
            setQRLink('#');
        }
    }, []);

    const getMainFundLink = useCallback( async () => {
        const res = await getMainLink();
        if (res[0]) {
            setMainLink(res[1]);
        } else {
            setMainLink('#');
        }
    }, []);

    const getSnapFundLink = useCallback( async () => {
        const res = await getSnapRaiseLink();
        if (res[0]) {
            setSnapLink(res[1]);
        } else {
            setSnapLink('#');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await getQRLink();
            await getMainFundLink();
            await getSnapFundLink();
        }
        fetchData();
    }, [getQRLink, getMainFundLink, getSnapFundLink])

    return (
        <section id="donate-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Donations</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="donate-title-div" className="donate-title">
                <h1>Want to Help Support Us?</h1>
                <h2>Consider donating today!</h2>
            </div>
            <div id="donate-wrapper-div" className="donate-wrapper-div">
                <div id="donate-content" className="donate-content">
                    <div className="donate-content-center">
                        {action === 'qr-scan' && (
                            <p>
                                (QR Code Donation Link: <a style={{color: 'white'}} href={`${qrLink}`} rel="noreferrer" target="_blank">Click Here</a>)
                            </p>
                        )}
                    </div>
                    <div id="donate-info">
                        <div className="donate-content-split">
                            <div className="split-left">
                                <img src={join} alt="donation.jpeg" />
                            </div>
                            <div className="split-right">
                                <h2>What Is Your Donation Used For?</h2>
                                <p>
                                    Your support will help us to continue provide a fun and exciting atmosphere to basketball games and other campus events! Donations will help us provide scholarships for our students as well as help cover expenses for equipment like uniforms, instrument repairs, instrument maintenance, and expand our music library. Please consider making a donation to either of the fundraising portals below. 
                                </p>

                                <h2 id="portal-title">Fundraising Portals</h2>
                                <div className="portal-buttons">
                                    <Link 
                                        to={mainLink}
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        <button className="main-large">Athletics Fundraising Portal</button>
                                    </Link>
                                    <Link 
                                        to={snapLink}
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        <button className="main-large">SnapRaise Fundraising Portal</button>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

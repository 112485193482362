import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { 
    Box, 
    Card, 
    CardContent, 
    TextField, 
    InputAdornment, 
    FormHelperText, 
    CardHeader 
} from '@mui/material';
import { AlertBanner } from '../Alerts/AlertBanner';
import { IoReloadSharp } from 'react-icons/io5';
import { BsPencilFill } from 'react-icons/bs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { v4 as uuidv4 } from 'uuid';
import { createNewAttendanceForm, generateCode } from '../../api/firebaseApi';
import '../../styles/forms.scss';

// Define screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
    tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
    medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
    large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
    xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
};

// Define form state types
type FormState = {
    Title: string;
    StartDate: null | Dayjs;
    ExpirationDate: null | Dayjs;
    FormUUID: string;
    Username: string | null;
    EncryptedPin: Promise<string> | string;
};

export function CreateNewAttendanceForm(props: { alert: Function; close: Function; refresh: Function; username: string; }) {

    // Set states
    const { alert, close, refresh, username } = props;
    const localUsername = localStorage.getItem('username');

    // Define initial form states
    const initialFormState = {
        Title: '',
        StartDate: dayjs(),
        ExpirationDate: dayjs().add(3, 'hour'),
        FormUUID: uuidv4(),
        Username: localUsername ? localUsername : username,
        EncryptedPin: '',
    };
    const [screenSize, setScreenSize] = useState(screens);
    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [errorDates, setErrorDates] = useState<boolean>(false);
    const [datesEqual, setDatesEqual] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isSelected1, setIsSelected1] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(370)

    // useEffect for screen size changes
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
            tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
            medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
            large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
            xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    // useEffect for changing state variables based on screen size
    useEffect(() => {
        if (screenSize.small) {
            setWidth(250);
        } else if (screenSize.tabletPort) {
            setWidth(350);
        } else if (screenSize.tabletLand) {
            setWidth(400);
        } else if (screenSize.large) {
            setWidth(500);
        } else if (screenSize.xlarge) {
            setWidth(500);
        } else {
            setWidth(370);
        }
    }, [screenSize]);

    const alertHandler = (payload: object) => {
        alert(payload);
    };

    // Set new UUID
    const newUUID = () => {
        const updatedFormState = { ...formState };
        updatedFormState.FormUUID = uuidv4();
        setFormState(updatedFormState);
    };

    // Check dates
    const checkDates = (start: Dayjs | null, end: Dayjs | null) => {
        if (start !== null && end !== null) {
            const startDate = new Date(start.toISOString());
            const endDate = new Date(end.toISOString());
            const comp =  startDate > endDate;
            const eq = startDate.toUTCString() === endDate.toUTCString();

            if (comp) {
                setErrorDates(true);
                return false;
            } else if (eq) {
                setDatesEqual(true);
                return false;
            } else {
                setDatesEqual(false);
                setErrorDates(false);
                return true;
            }
        }
    };

    // Function to get a new encrypted pin via API call
    const generatePin = async () => {
        const response = await generateCode();
        if (response[0] && typeof response[1] === 'number') {
            const pin:number = response[1];
            return pin.toString();
        } else {
            const errorPayload = {
                id: uuidv4(),
                type: 'error',
                message: response[1]
            };
            alertHandler(errorPayload);
            return 'error';
        }
    };

    // Check to see if there are any input errors
    const checkErrors = () => {
        const dates = checkDates(formState.StartDate, formState.ExpirationDate);
        if (!dates) {
            return false;
        } else if (formState.Title === '') {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: 'Title field cannot be blank',
            };
            alertHandler(payload);
            return false;
        }
        return true;
    }

    const capitalizeFirstLetter = (input:string) => {
        return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    };

    // Form submission function
    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        if (checkErrors()) {
            setSubmitting(true);
            const formPayload = { ...formState };
            let title = formPayload.Title.split(' ');
            for (let i = 0; i < title.length; i++) {
                title[i] = capitalizeFirstLetter(title[i]);
            }
            formPayload.Title = title.join(' ');
            const newPin = await generatePin();
            if (newPin !== 'error') {
                formPayload.EncryptedPin = newPin;
                const response = await createNewAttendanceForm(formPayload);
                if (response[0]) {
                    const payload = {
                        id: uuidv4(),
                        type: 'success',
                        message: 'Form created successfully',
                    };
                    alertHandler(payload);
                    refresh();
                    setFormState(initialFormState);
                } else {
                    const payload = {
                        id: uuidv4(),
                        type: 'error',
                        message: response[1],
                    }
                    alertHandler(payload);
                }
            }
            setSubmitting(false);
        }
    };

    const closeModalHandler = () => {
        close();
    };

    const closeAlertHandler = (banner: string) => {
        if (banner === 'dates') {
            setErrorDates(false);
        } else if (banner === 'dates-equal') {
            setDatesEqual(false);
        }
    }

    // Form controller title
    const updateFormControlTitle = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
    ) => {
        const { value } = event.target;
        const updatedFormState = { ...formState };
        updatedFormState.Title = value;
        setFormState(updatedFormState);
    };

    // Form controller start date
    const updateFormControlStartDate = (newValue: Dayjs | null) => {
        const updatedFormState = { ...formState };
        if (newValue === null) { 
            newValue = dayjs(); 
        }
        updatedFormState.StartDate = newValue;
        setFormState(updatedFormState);
    };

    // Form controller expiration date
    const updateFormControlExpirationDate = (newValue: Dayjs | null) => {
        const updatedFormState = { ...formState };
        if (newValue === null) { 
            newValue = dayjs().add(24, 'hour'); 
        }
        updatedFormState.ExpirationDate = newValue;
        setFormState(updatedFormState);
    };

    // Title Adornment
    const titleAdornment = isSelected1
        ? {
            startAdornment: (
                <InputAdornment position="start">
                    <BsPencilFill style={{fontSize: "110%"}}/>
                </InputAdornment>
            )
        }
    : {};

    return (
        <>
            <div id="create-new-attendance-form">
                <Box sx={{ display: 'inline-block'}}>
                    <Card>
                        <form>
                            <CardHeader title="Create New Attendance Form" />
                            <CardContent>

                                {/* Title */}
                                <div className="padded">
                                    <TextField 
                                    required
                                    id="Title" 
                                    label="Title"
                                    variant="outlined"
                                    type="text"
                                    onChange={updateFormControlTitle}
                                    value={formState.Title} 
                                    sx={{width}}
                                    InputProps={titleAdornment}
                                    onFocus={e => setIsSelected1(true)}
                                    onBlur={formState.Title ? undefined : e => setIsSelected1(false)}
                                    onInput={e => setIsSelected1(true)}
                                    />
                                </div>

                                {/* Date errors */}
                                {errorDates && (
                                    <div className="padded">
                                        <AlertBanner type="error" message="Start date must be BEFORE end date." close={() => closeAlertHandler('dates')} />
                                    </div>
                                )}

                                {datesEqual && (
                                    <div className="padded">
                                        <AlertBanner type="error" message="Start date and end date cannot be the same." close={() => closeAlertHandler('dates-equal')} />
                                    </div>
                                )}

                                {/* Begin Date */}
                                <div className="padded">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker 
                                            label="Choose a start date/time" 
                                            value={formState.StartDate}
                                            onChange={(newValue) => updateFormControlStartDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>

                                {/* Expiration Date */}
                                <div className="padded">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker 
                                            label="Choose an end date/time" 
                                            value={formState.ExpirationDate}
                                            onChange={(newValue) => updateFormControlExpirationDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>

                                {/* Form UUID */}
                                <div className="padded">
                                    <FormHelperText>
                                        Form UUID:&nbsp;
                                        <span className="font-bg1">{formState.FormUUID}</span>&nbsp;
                                        <IoReloadSharp className="uuid-reload" onClick={newUUID} />
                                    </FormHelperText> 
                                </div>
                            
                            </CardContent>
                        </form>
                    </Card>
                </Box>
            </div>
            <br />
            <button 
                className="main-button modal" 
                disabled={submitting}
                onClick={submitForm}
            >
                {submitting ? "Creating..." : "Create"}
            </button>
            <button className="outline-button modal" onClick={closeModalHandler}>Cancel</button>
        </>
    );
};

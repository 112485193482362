import React from 'react';
import image from '../../assets/404.png';
import './NotFound.scss';

export function NotFoundRaw() {
    return (
        <div>
            <div>
                <img src={image} alt='not-found.png' width={200}/>
            </div>
            <h1>404: Page Not Found</h1>
            <p>Sorry, we couldn't find the page you were looking for.</p>
            <p id="check-back">Please check the URL and try again.</p>
        </div>
    );
};

import React from 'react';
import { TablePagination } from '@mui/base/TablePagination';
import './DataTablePagination.scss';

export function DataTablePagination(props) {
    const { 
        length, 
        rowsPerPage, 
        page, 
        handlePageChange, 
        handleRowsPerPageChange, 
    } = props;
    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={7}
            count={length}
            rowsPerPage={rowsPerPage}
            page={page}
            slots={{root: 'div'}}
            slotProps={{
                select: {
                    'aria-label': 'rows per page',
                },
                    actions: {
                    showFirstButton: true,
                    showLastButton: true,
                },
            }}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
        />
    );
};

import React, { 
    ChangeEvent, 
    FormEvent, 
    useRef, 
    useEffect,
    useState 
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, 
    Card, 
    CardActions, 
    CardContent, 
    CardHeader, 
    Chip,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField, 
    Tooltip,
} from '@mui/material';
import { AlertBanner } from '../Alerts/AlertBanner';
import { RiArrowDropDownLine, RiSmartphoneFill } from 'react-icons/ri';
import { IoIosMail } from 'react-icons/io';
import { BsPersonCircle } from 'react-icons/bs';
import { MdLibraryMusic } from 'react-icons/md';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { FcInfo } from 'react-icons/fc';
import { HiSearch } from 'react-icons/hi';
import emailjs from 'emailjs-com';
import dayjs from 'dayjs';
import '../../styles/forms.scss';

// Define screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
    tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
    medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
    large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
    xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
};

export function InterestForm(props: { alert: Function; }) {

    // Initialize form state
    const initialFormState = {
        first: '',
        last: '',
        email: '',
        phone: '',
        year: '',
        otherYear: '',
        instrumentSearch: '',
        experience: '',
        date: '',
    };
    
    // Initial instrument array
    const instrumentList: string[] = [];
    const initialInstrumentFormState = { instrument: [] };

    // Define instrument list
    const instrumentOptionsList = [
        'Flute',
        'Piccolo',
        'Oboe',
        'Clarinet',
        'Alto Saxophone',
        'Tenor Saxophone',
        'Bari Saxophone',
        'Trumpet',
        'Mellophone',
        'Baritone',
        'Trombone',
        'Tuba',
        'Bass Guitar',
        'Guitar',
        'Drum Set',
        'Snare Drum',
        'Tenor Drums',
        'Bass Drum',
        'Cymbals',
        'Keyboard',
    ];

    // Form types
    type FormState = {
        first: string;
        last: string;
        email: string;
        phone: string;
        year: string;
        otherYear: string;
        instrumentSearch: string;
        experience: string;
        date: string;
    };
    
    // Instrument list type
    type FormStateInstrument = {
        instrument: string[];
    }

    // Get the component props and initialize useStates
    const { alert } = props;
    const [screenSize, setScreenSize] = useState(screens);
    const [fieldWidth, setFieldWidth] = useState(400);
    const [searchWidth, setSearchWidth] = useState(400/1.07);
    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [recaptchaToken, setReCaptchaToken] = useState<string>();
    const [formStateInst, setFormStateInst] = useState<FormStateInstrument>(initialInstrumentFormState);
    const [instruments, setInstruments] = useState(instrumentList);
    const [instrumentOptions, setInstrumentsOptions] = useState(instrumentOptionsList);
    const [showError, setShowError] = useState(false);
    const [limitReached, setLimitReached] = useState<boolean>(false);
    const [maxAlerts, setMaxAlerts] = useState<number>(0);
    const [isSelected1, setIsSelected1] = useState(false);
    const [isSelected2, setIsSelected2] = useState(false);
    const [isSelected3, setIsSelected3] = useState(false);
    const [isSelected4, setIsSelected4] = useState(false);
    const [isSelected6, setIsSelected6] = useState(false);
    const [isSelected7, setIsSelected7] = useState(false);
    const charLimit = 500;

    // Experience helper message
    const msgEx = 'Please provide a short summary of your musical experience. Your expereience level will not affect your audition process or placement.';

    // Handler for floating alert messages
    const showAlert = (alertPayload: object) => {
        alert(alertPayload);
    };

    // useEffect for screen size changes
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
            tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
            medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
            large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
            xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    // useEffect for changing state variables based on screen size
    useEffect(() => {
        if (screenSize.small) {
            setFieldWidth(250);
            setSearchWidth(250/1.18);
        } else if (screenSize.tabletPort) {
            setFieldWidth(350);
            setSearchWidth(350/1.07);
        } else if (screenSize.tabletLand) {
            setFieldWidth(400);
            setSearchWidth(400/1.07);
        } else if (screenSize.large) {
            setFieldWidth(390);
            setSearchWidth(500/1.07);
        } else if (screenSize.xlarge) {
            setFieldWidth(500);
            setSearchWidth(500/1.07);
        } else {
            setFieldWidth(370);
            setSearchWidth(400/1.2);
        }
    }, [screenSize]);
  
    // Formspark API
    const formId = 'gzPXn40i';
    const formSparkUrl = `https://submit-form.com/${formId}`;
  
    // ReCaptcha API
    const recaptchaKey = '6LcxdJIgAAAAANO7EmGWo9NXwiceHw4HG2VqzEXV';
    const recaptchaRef = useRef<any>();

    // Set ReCaptcha token
    const updateRecaptchaToken = (token: string | null) => {
        setReCaptchaToken(token as string);
    };
  
    // Function to delete an instrument chip
    const handleDeleteChip = (instrumentToDelete: string) => {
        const updatedInstruments = instruments.filter((instrument) => instrument !== instrumentToDelete);
        setInstruments(updatedInstruments);
        if (updatedInstruments.length === 0) { 
            setShowError(true);
            const message = {
                id: uuidv4(),
                type: 'error',
                message: 'Please add at least one instrument.',
            };
            showAlert(message);
        } else { 
            setShowError(false); 
        }
    };
  
    // Function to add instrument chip
    const addInstrument = (instrument: string) => {
        let exists = false;
        let addArray: string[] = [];
        for (var item of instruments) {
            addArray.push(item);
            if (item === instrument) { 
                exists = true; 
            }
        }
    
        if (!exists) { 
            let letter = instrument.charAt(0).toUpperCase();
            let cut = instrument.substring(1).toLowerCase();
            let capInstrument = `${letter}${cut}`;
            addArray.push(capInstrument); 
            setShowError(false);
        }
        setInstruments(addArray);
    };
  
    // Function to filter instrument options
    const filterInstrumentList = (filterOn: string) => {
        if (filterOn === '') {
            setInstrumentsOptions(instrumentOptionsList)
        } else { 
            const filteredInstruments: string[] = instrumentOptions.filter((instrument) =>
                instrument.toLowerCase().includes(filterOn.toLowerCase())
            );
            setInstrumentsOptions(filteredInstruments)
        }
    };
  
    // Function to submit form
    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitting(true);
        if (!limitReached && instruments.length > 0) {
            await postSubmission();
        } else if (instruments.length === 0) {
            const message = {
                id: uuidv4(),
                type: 'error',
                message: 'Please add at least one instrument.',
            };
            showAlert(message);
        }
        setSubmitting(false);
    };
  
    // Submit API
    const postSubmission = async () => {
        let phone = formState.phone;
        if (!phone) { phone = 'NONE' }
        let letter = formState.first.charAt(0).toUpperCase();
        let cut = formState.first.substring(1).toLowerCase();
        let firstName = `${letter}${cut}`;

        letter = formState.last.charAt(0).toUpperCase();
        cut = formState.last.substring(1).toLowerCase();
        let lastName = `${letter}${cut}`;

        const formEntries = {
            first: firstName,
            last: lastName,
            email: formState.email,
            phone: phone,
            year: formState.year,
            otherYear: formState.otherYear,
            instruments: instruments,
            experience: formState.experience,
            date: formState.date,
        };

        const payload = {
            ...formEntries,
            'g-recaptcha-response': recaptchaToken,
        };
    
        try {
            // Make post to Formspark API
            await axios.post(formSparkUrl, payload);

            // Format instrument list
            let instrumentStr: string = '';
            for (let key in instruments) {
                if (parseInt(key) === instruments.length - 1) {
                    instrumentStr = instrumentStr.concat(instruments[key]);
                } else {
                    instrumentStr = instrumentStr.concat(instruments[key].concat(', '));
                }
            }

            // Prepare confirmation email
            const emailContent = `
                Hello, ${firstName}!
            
                Thank you for your interest in joining Chucktown Sound!
            
                Here is your for submission info:
            
                Name: ${firstName} ${lastName}
                Email: ${formState.email}${formState.phone ? `\nPhone: ${formState.phone}` : ''}
                Year: ${formState.year}${formState.otherYear ? `\nOther: ${formState.otherYear}` : ''}
                Instrument(s): ${instrumentStr}
                Experience: ${formState.experience}
                Date: ${formState.date}
    
                Please be sure to view your audition materials and submit your audition video. If you have submitted your audition video and you have not heard from our director about the next steps within 7 days, please reach out to Nick Nafpliotis at nnafplio@cofc.edu.
        
                Looking forward to working with you!
                - Chucktown Sound Staff
            
                ** PLEASE DO NOT REPLY TO THIS EMAIL **
            `;
    
            const emailSubject = 'CTS INTEREST FORM CONFIRMATION';
    
            // Configure EmailJS service credentials
            emailjs.init('OU6kvPYo-aakFe_DO');
    
            // Prepare the email parameters
            const emailParams = {
                to_email: formState.email,
                from_email: 'chucktown.soundcofc@gmail.com',
                subject: emailSubject,
                content: emailContent,
            };
    
            // Send the email using EmailJS
            await emailjs.send('chucktown_sound_it', 'sub_confirm', emailParams);
            console.log('Notification email sent.');
    
            // Show floating success alert message
            const message = {
                id: uuidv4(),
                type: 'success',
                message: `Success! Confirmation email sent to: ${formState.email}`,
            };
            showAlert(message);

            setFormState(initialFormState);
            setFormStateInst(initialInstrumentFormState);
            setInstruments(instrumentList);
            setInstrumentsOptions(instrumentOptionsList);
            recaptchaRef.current.reset();
        } catch (error) {
            console.error(error);
            // Show floating error alert message
            const message = {
                id: uuidv4(),
                type: 'error',
                message: error,
            };
            showAlert(message);
        };
    };
  
    // Update form text field
    const updateFormControl = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = event.target;
        const key = id as keyof FormState;
        const updatedFormState = { ...formState };
        if (id === 'instrumentSearch') {
            updatedFormState['instrumentSearch'] = value;
            filterInstrumentList(value);
        } else { 
            if (id === 'experience') {
                if (value.length > charLimit) {
                    setLimitReached(true);
                    const message = {
                        id: uuidv4(),
                        type: 'warn',
                        message: `Character limit reached!`,
                    };
                    setMaxAlerts(maxAlerts + 1);
                    if (maxAlerts < 1) {
                        showAlert(message);
                    }
                } else {
                    setLimitReached(false);
                    setMaxAlerts(0)
                }
            }
            updatedFormState[key] = value; 
        }
        updatedFormState['date'] = getDate();
        setFormState(updatedFormState);
    };
  
    // Handler to update form dropdown selection for student year
    const updateFormControlSelect = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value;
        const updatedFormState = { ...formState };
        updatedFormState['year'] = value;
        updatedFormState['date'] = getDate();
        setFormState(updatedFormState);
    };
  
    // Handler to update for dropdown selection for instrument
    const updateFormControlSelect2 = (value: string, event: FormEvent) => {
        event.preventDefault();
        addInstrument(value);
  
        let newArray: string[] = [];
        for (let item of formStateInst.instrument) {
            newArray.push(item);
        }
        newArray.push(value);
        const updatedFormState = { ...formStateInst };
        const updatedformState = { ...formState };
        updatedFormState['instrument'] = newArray;
        updatedformState['date'] = getDate();
        setFormStateInst(updatedFormState);
        setFormState(updatedformState);
    };
  
    // Function to get and format current date & time
    function getDate() {
        return dayjs().format('MM/DD/YYYY hh:mm A');
    };
  
    // Form title
    const title = <><MdLibraryMusic /> Interest Form</> ;

    // First name adornment
    const fnameAdornment = isSelected1
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <BsPersonCircle />
          </InputAdornment>
        )
    }
    : {};
    
    // Last name adornments
    const lnameAdornment = isSelected2
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <BsPersonCircle />
          </InputAdornment>
        )
    }
    : {};
    
    // Email adornment
    const emailAdornment = isSelected3
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <IoIosMail style={{fontSize: '110%'}} />
          </InputAdornment>
        )
    }
    : {};
  
    // Phone number adornment
    const phoneAdornment = isSelected4
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <RiSmartphoneFill style={{fontSize: "110%"}}/>
          </InputAdornment>
        )
    }
    : {};
  
    // Other adornment
    const otherAdornment = isSelected6
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <AiFillQuestionCircle style={{fontSize: "110%"}}/>
          </InputAdornment>
        )
    }
    : {};
  
    // Searchbox adornment
    const searchAdornment = isSelected7
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <HiSearch style={{fontSize: "110%"}}/>
          </InputAdornment>
        )
    }
    : {};
  
    // Other selection function
    function isOther() {
        let other = false;
        if (formState.year === 'Other') {
            other = true;
        }
        return other;
    }

    const handleErrorClose = () => {
        setShowError(false);
    };

    return (
        <>
            <div id="form">
                <Box sx={{display: 'inline-block'}}>
                    <Card className="MuiCard-root form-card">
                        <CardHeader title={title} />
                        
                        {/* Required Indicator */}
                        <Chip 
                            className="MuiChip-root info"
                            icon={<FcInfo style={{transform: 'translateY(-2px)'}} />} 
                            label={
                                <span>
                                    <span style={{color: '#db3131'}}>*</span>
                                    &nbsp;Required Field
                                </span>
                            } 
                            variant="outlined" 
                        />
                        
                        {/* Form */}
                        <form onSubmit={submitForm}>
                            <CardContent> 
                                {/* First Name */}
                                <div className="padded">
                                    <TextField 
                                        required
                                        error={false}
                                        id="first" 
                                        label="First Name" 
                                        variant="outlined"
                                        onChange={updateFormControl}
                                        value={formState?.first} 
                                        sx={{width: fieldWidth}}
                                        InputProps={fnameAdornment}
                                        onFocus={e => setIsSelected1(true)}
                                        onBlur={formState.first ? undefined : e => setIsSelected1(false)}
                                        onInput={e => setIsSelected1(true)}
                                    />
                                </div> 

                                {/* Last Name */}
                                <div className="padded">
                                    <TextField 
                                        required
                                        id="last" 
                                        label="Last Name" 
                                        variant="outlined"
                                        onChange={updateFormControl}
                                        value={formState?.last} 
                                        sx={{width: fieldWidth}}
                                        InputProps={lnameAdornment}
                                        onFocus={e => setIsSelected2(true)}
                                        onBlur={formState.last ? undefined : e => setIsSelected2(false)}
                                        onInput={e => setIsSelected2(true)}
                                    />
                                </div> 

                                {/* Email */}
                                <div className="padded">
                                    <TextField 
                                        required
                                        id="email" 
                                        label="Email"
                                        variant="outlined"
                                        type="email"
                                        onChange={updateFormControl}
                                        value={formState?.email} 
                                        sx={{width: fieldWidth}}
                                        InputProps={emailAdornment}
                                        onFocus={e => setIsSelected3(true)}
                                        onBlur={formState.email ? undefined : e => setIsSelected3(false)}
                                        onInput={e => setIsSelected3(true)}
                                    />
                                </div>

                                {/* Phone */}
                                <div className="padded" style={{marginBottom: -15}}>
                                    <TextField 
                                        id="phone" 
                                        label="Phone Number"
                                        placeholder="XXX-XXX-XXXX"
                                        inputProps={{pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}"}}
                                        variant="outlined"
                                        type="text"
                                        helperText={
                                            <span className="helper">
                                                (Optional)&nbsp;
                                                <Tooltip title="Your phone number will be used for professional uses ONLY and will not be shared outside of CTS staff.">
                                                    <span><AiFillQuestionCircle className="icon-black" /></span>
                                                </Tooltip>
                                            </span>
                                        }
                                        onChange={updateFormControl}
                                        value={formState?.phone} 
                                        sx={{width: fieldWidth}}
                                        InputProps={phoneAdornment}
                                        onFocus={e => setIsSelected4(true)}
                                        onBlur={formState.phone ? undefined : e => setIsSelected4(false)}
                                        onInput={e => setIsSelected4(true)}
                                    />
                                </div>

                                {/* Year */}
                                <div className="padded" style={screenSize.small ? {} : {display: 'inline-block'}}>
                                    <FormControl>
                                        <InputLabel required id="year-label">Academic Year</InputLabel>
                                        <Select
                                            required
                                            labelId="year-label"
                                            id="year"
                                            variant="outlined"
                                            value={formState?.year}
                                            label="Academic Year"
                                            onChange={updateFormControlSelect}
                                            sx={{width: fieldWidth, textAlign: 'left'}}
                                        >
                                            <MenuItem value={'Freshman'}>Freshman</MenuItem>
                                            <MenuItem value={'Sophomore'}>Sophomore</MenuItem>
                                            <MenuItem value={'Junior'}>Junior</MenuItem>
                                            <MenuItem value={'Senior'}>Senior</MenuItem>
                                            <MenuItem value={'Other'}>Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {/* Selected Other */}
                                {isOther() && <div className="padded">
                                    <TextField 
                                        required
                                        id="otherYear" 
                                        label="Please Specify Other" 
                                        variant="outlined"
                                        onChange={updateFormControl}
                                        value={formState?.otherYear} 
                                        sx={{width: fieldWidth}}
                                        InputProps={otherAdornment}
                                        onFocus={e => setIsSelected6(true)}
                                        onBlur={formState.otherYear ? undefined : e => setIsSelected6(false)}
                                        onInput={e => setIsSelected6(true)}
                                    />
                                </div>}

                                {/* Instrument Error */}
                                {showError && (
                                    <div className="padded">
                                        <AlertBanner 
                                            type="error" 
                                            message="Please add at least one instrument." 
                                            close={handleErrorClose}
                                        />
                                    </div>
                                )}

                                {/* Instrument */}
                                <div className="padded">
                                    <InputLabel required style={{textAlign: 'left', paddingBottom: '0.5em'}}>
                                        Instrument(s)
                                    </InputLabel>
                                    <div className="instruments-div" style={{width: fieldWidth}}>
                                        {instruments.length === 0 && (
                                            <Chip 
                                                style={{color: '#2c94f8', fontWeight: 'bold'}} 
                                                icon={<FcInfo style={{transform: 'translateY(-2px)'}} />} 
                                                label="Please select the instrument(s) that you currently play." 
                                                variant="outlined"
                                            />
                                        )}
                                        {instruments?.map((instrument) => {
                                            return (
                                                <div style={{padding: '5px 0 0 5px'}}>
                                                    <Chip 
                                                        key={`${instrument}-chip`}
                                                        label={instrument}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(instrument)}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <FormControl>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
                                                Instrument Options
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{padding: '0.5rem 0'}}>
                                                    <TextField 
                                                        id="instrumentSearch" 
                                                        helperText="Search for an instrument here. Click an instrument to add it to your profile."
                                                        label="Search" 
                                                        variant="outlined"
                                                        value={formState.instrumentSearch}
                                                        onChange={updateFormControl}
                                                        sx={{width: searchWidth}}
                                                        InputProps={searchAdornment}
                                                        onFocus={e => setIsSelected7(true)}
                                                        onBlur={formState.instrumentSearch ? undefined : e => setIsSelected7(false)}
                                                        onInput={e => setIsSelected7(true)}
                                                    />
                                                </div>

                                                {instrumentOptions.length === 0 && (
                                                    <div style={{paddingBottom: 25}}>
                                                        <button 
                                                            className="instrument-button"
                                                            onClick={(event) => updateFormControlSelect2(formState.instrumentSearch, event)}
                                                        >
                                                            ADD '{formState.instrumentSearch.toUpperCase()}'
                                                        </button>
                                                    </div>
                                                )}
                                                
                                                {instrumentOptions.map((instrument, index) => {
                                                    let padStyling = {};

                                                    if (index === 0 && instrumentOptions.length !== 1) {
                                                        padStyling = { paddingTop: 5 }
                                                    } else if (index === instrumentOptions.length - 1) {
                                                        padStyling = { paddingBottom: 25 }
                                                    } else {
                                                        padStyling = { padding: 2.5 }
                                                    }
                                                    return (
                                                        <div style={padStyling}>
                                                            <button 
                                                                className="instrument-button"
                                                                onClick={(event) => updateFormControlSelect2(instrument, event)}
                                                            >
                                                                {instrument.toUpperCase()}
                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    </FormControl>
                                </div>

                                {/* Experience */}
                                <div className="padded">
                                    <TextField 
                                        multiline
                                        required
                                        id="experience" 
                                        label="Experience" 
                                        type='text'
                                        placeholder={msgEx}
                                        helperText={
                                            <span className={limitReached ? 'form-error' : ''}>
                                                {`${formState.experience.length} / ${charLimit} Characters`}
                                            </span>
                                        }
                                        error={limitReached}
                                        variant="outlined"
                                        onChange={updateFormControl}
                                        value={formState?.experience.replace(/↵/g, '\n')} 
                                        rows={4}
                                        sx={{width: fieldWidth}}
                                    />
                                </div>

                                {/* ReCAPTCHA */}
                                <div className="padded" style={{width: fieldWidth}}>
                                    <ReCAPTCHA
                                        style={{
                                            transform: 'scale(0.77)', 
                                            WebkitTransform: 'scale(0.77)', 
                                            transformOrigin: '0 0', 
                                            WebkitTransformOrigin: '0 0'
                                        }}
                                        ref={recaptchaRef}
                                        sitekey={recaptchaKey}
                                        onChange={updateRecaptchaToken}
                                    />
                                </div>
                            </CardContent>

                            {/* Date/Time */}
                            <input type='hidden' id="date" value={formState?.date} />

                            <CardActions className="card-actions">
                                <button className="main-button" disabled={submitting} style={{marginTop: '-30px'}}>
                                    {submitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </CardActions>
                        </form>    
                    </Card>
                </Box>
            </div>
        </>
    );
};

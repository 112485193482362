import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { initialAccount } from './components/Utils/JsonConstants';
import Dashboard from './pages/Dashboard/Dashboard';
import { IconContext } from 'react-icons/lib';
import { Sidebar, SidebarMobile } from './components/Sidebar/Sidebar';
import { About } from './pages/About/About';
import { Schedule } from './pages/Schedule/Schedule';
import { AlertStack } from './components/Alerts/AlertStack';
import { Home } from './pages/Home/Home';
import { Auditions } from './pages/Auditions/Auditions';
import { Contact } from './pages/Contact/Contact';
import { ComingSoon } from './pages/ComingSoon/ComingSoon';
import { NotFound } from './pages/NotFound/NotFound';
import { Login } from './pages/Login/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { ReportAProblem } from './pages/ReportAProblem/ReportAProblem';
import { CreateAccount } from './pages/CreateAccount/CreateAccount';
import { AccessDenied } from './pages/AccessDenied/AccessDenied';
import Admin from './pages/Admin/Admin';
import Donate from './pages/Donate/Donate';
import { v4 as uuidv4 } from 'uuid';
import './App.scss';

// Define alternate screen sizes
const screens = {
  small: window.matchMedia("all and (max-device-width: 640px)").matches,
  tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
};

// Terms and Conditions link
const pdfLink = 'https://drive.google.com/file/d/1_T8lxXQeR80_ESPP9hBslaw7Rcwxt1LY/view?usp=sharing';

// Main app
function App() {
  // Intialize state variables
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [accountData, setAccountData] = useState(initialAccount);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [index, setIndex] = useState(0);

  // Handler for Terms and Conditions PDF
  const handlePdfLinkClick = () => {
    window.open(pdfLink, '_blank');
  };

  // Function to handle new alert messages
  const newAlertHandler = useCallback((newAlert) => {
    const alertList = [...alerts];
    if (newAlert instanceof Array) {
      for (let i = 0; i < newAlert.length; i++) {
        alertList.push(newAlert[i]);
      }
    } else {
      alertList.push(newAlert);
    }
    setAlerts(alertList);
  }, [alerts]);

  // Function to handle closing alert messages
  const closeAlertHandler = useCallback((id) => {
    const alertList = [...alerts];
    for (let i = 0; i < alertList.length; i++) {
        if (alertList[i].id === id) {
          alertList.splice(i, 1); 
        }
    }
    setAlerts(alertList);
  }, [alerts]);

  // Function to handle logged user change
  const userHandler = (newUser) => {
    if (!newUser) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', JSON.stringify(newUser));
    }
    setUser(newUser);
  };

  // Function to handle account data change
  const setAccountHandler = (data) => {
    setAccountData(data);
  };

  useEffect(() => {
    const agree = localStorage.getItem('cookieAgree');
    if (index === 0 && (agree === undefined || agree === null)) {
      setIndex(1);
      const id = uuidv4();
      const cookieAlert = {
        id,
        type: 'warn',
        message: 
          <span id='cookie-policy-alert'>
            <span>
              We use cookies on this site to enhance your browsing experience and improve our services. For more information, please check out our <Link to="#" onClick={handlePdfLinkClick}>Terms & Conditions</Link>
            </span>
            <span id="cookie-buttons">
                <button 
                  className="main-button" 
                  onClick={() => { 
                    localStorage.setItem('cookieAgree', true);
                    closeAlertHandler(id); 
                  }}
                >
                  I Acknowledge and Agree
                </button>
            </span>
          </span>,
        noClose: true,
      }
      newAlertHandler(cookieAlert);
    }
  }, [newAlertHandler, index, closeAlertHandler]);

  return (
    <Router>
      <main>
        <IconContext.Provider value={{className: 'translator'}}>
          {
            screens.small 
            ? <SidebarMobile /> 
            : <Sidebar 
                alert={newAlertHandler}
                user={user}
                isOpen={sideBarOpen} 
                account={accountData}
                setUser={userHandler}
                changeOpen={() => setSideBarOpen(!sideBarOpen)} 
              />
          }
          <Routes>
            <Route>
              {/* Home */}
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home sideBarOpen={sideBarOpen} />} />

              <Route element={<PrivateRoute user={user} setUser={userHandler} sideBarOpen={sideBarOpen} />}>
                {/* Admin */}
                <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />  
                <Route 
                  path="/admin/dashboard" 
                  element={
                    <Admin 
                      user={user}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={newAlertHandler}
                      close={closeAlertHandler}
                    />
                  } 
                />
                <Route 
                  path="/admin/dashboard/:nav" 
                  element={
                    <Admin 
                      user={user}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={newAlertHandler}
                      close={closeAlertHandler}
                    />
                  } 
                />
                <Route 
                  path="/admin/dashboard/:nav/:action" 
                  element={
                    <Admin 
                      user={user}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={newAlertHandler}
                      close={closeAlertHandler}
                  />
                  } 
                />

                {/* Account */}
                <Route 
                  path="/dashboard/" 
                  element={
                    <Dashboard 
                      user={user}
                      setUser={userHandler}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={newAlertHandler}
                      close={closeAlertHandler}
                    />
                  }
                />
                <Route 
                  path="/dashboard/:nav" 
                  element={
                    <Dashboard 
                      user={user}
                      setUser={userHandler}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={newAlertHandler}
                      close={closeAlertHandler}
                    />
                  }
                />
              </Route>
              <Route path="/login" element={<Login sideBarOpen={sideBarOpen} alert={newAlertHandler} />} />
              <Route path="/create-account" element={<CreateAccount sideBarOpen={sideBarOpen} alert={newAlertHandler} />} />

              {/* About */}
              <Route path="/about" element={<About sideBarOpen={sideBarOpen} />} />

              {/* Donate */}
              <Route path="/donate" element={<Donate sideBarOpen={sideBarOpen} />} />

              {/* Media */}
              <Route path="/media/" element={<Navigate to="/media/photos" />} />
              <Route path="/media/photos" element={<ComingSoon sideBarOpen={sideBarOpen} />} />
              <Route path="/media/videos" element={<ComingSoon sideBarOpen={sideBarOpen} />} />

              {/* Schedule */}
              <Route path="/schedule" element={<Schedule sideBarOpen={sideBarOpen} />} />

              {/* Auditions */}
              <Route path="/auditions" element={<Auditions sideBarOpen={sideBarOpen} alert={newAlertHandler} />} />

              {/* Contact */}
              <Route path="/contact" element={<Contact sideBarOpen={sideBarOpen} alert={newAlertHandler} />} />

              {/* Report */}
              <Route path="/report-a-problem-form" element={<ReportAProblem sideBarOpen={sideBarOpen} alert={newAlertHandler} />} />

              {/* Other */}
              <Route path="/access-denied" element={<AccessDenied sideBarOpen={sideBarOpen} />} />
              <Route path="/loading-screen" element={<h1>loading screen</h1>} />
              <Route path="*" element={<NotFound sideBarOpen={sideBarOpen} />} />
            </Route>
          </Routes>
          <AlertStack alerts={alerts} close={closeAlertHandler} />
        </IconContext.Provider>
      </main>
    </Router>
  );
};

export default App;

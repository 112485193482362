import React, { useEffect, useState, FormEvent, ChangeEvent } from 'react';
import { 
    Box, 
    Card, 
    CardContent,
    TextField, 
    InputAdornment, 
    FormHelperText, 
    CardHeader 
} from '@mui/material';
import { BsFillKeyFill } from 'react-icons/bs';
import { IoReloadSharp } from 'react-icons/io5';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { sendConfirmationEmail, signAttendanceForm, verifyCode } from '../../api/firebaseApi';
import '../../styles/forms.scss';

// Define alternate screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
};

// Define form state types
type FormState = {
    Title: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Timestamp: null | string;
    Verified: boolean;
    SubmissionUuid: string;
    inputCode: string | null | undefined;
};

type UserData = {
    Email: string;
    FirstName: string;
    LastName: string;
}

type Props = { 
    alert: Function; 
    close: Function; 
    refresh: Function;
    userData: UserData;
    uuid: string;
    title: string;
};

export function SignAttendanceForm(props: Props) {
    // Set states
    const { alert, close, refresh, userData, uuid, title } = props;
    const [formState, setFormState] = useState<FormState>({
        Title: title,
        Email: userData.Email,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Timestamp: null,
        Verified: false,
        SubmissionUuid: uuidv4(),
        inputCode: null,
    });
    const [error, setError] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    const alertHandler = (payload: object) => {
        alert(payload);
    };

    // Form submission function
    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitting(true);
        let isVerified:any;
        if (formState.inputCode) {
            isVerified = await verifyCode(uuid, `${formState.inputCode}`);
        }
        
        if (isVerified[0]) {
            if (isVerified[1]) {
                const formPayload = { ...formState };
                delete formPayload.inputCode;
                formPayload.Timestamp = dayjs().format('MM/DD/YYYY h:mm A');
                formPayload.Verified = isVerified[1];
                const response = await signAttendanceForm(uuid, formPayload);
                if (response[0]) {
                    const payload = [{
                        id: uuidv4(),
                        type: 'success',
                        message: 'Form signed successfully!',
                    }];
                    formPayload.SubmissionUuid = formState.SubmissionUuid;
                    const confirmationSent = await sendConfirmationEmail('Attendance', formPayload, [uuid]);
                    if (confirmationSent[0]) {
                        payload.push({
                            id: uuidv4(),
                            type: 'success',
                            message: `Confirmation email for: ${formPayload.Email} has been queued`,
                        })
                        alertHandler(payload);
                        refresh();
                        closeModalHandler();
                    }
                } else {
                    const payload = {
                        id: uuidv4(),
                        type: 'error',
                        message: response[1],
                    }
                    alertHandler(payload);
                }
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: 'Incorrect code.',
                }
                setError(true);
                alertHandler(payload);
            }
        } else {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: isVerified[1],
            }
            alertHandler(payload);
        }
        setSubmitting(false);
    };

    const closeModalHandler = () => {
        close();
    };

    // Set new UUID
    const newUUID = () => {
        const updatedFormState = { ...formState };
        updatedFormState.SubmissionUuid = uuidv4();
        setFormState(updatedFormState);
    };

    // Form controller submission code
    const updateFormControl = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
    ) => {
        const { value } = event.target;
        const updatedFormState = { ...formState };
        updatedFormState.inputCode = value;
        setFormState(updatedFormState);
    };

    // Code Adornment
    const codeAdornment = isSelected
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <BsFillKeyFill style={{fontSize: "110%"}}/>
            </InputAdornment>
        )
        }
    : {};

    return (
        <>
            <div id="sign-attendance-form">
                <Box sx={{ display: 'inline-block', width: '100%'}}>
                    <Card>
                        <form>
                            <CardHeader title="Enter Submission Code" />
                            <h3>Form: {formState.Title}</h3>
                            <h5>{uuid}</h5>
                            <CardContent>
                                {/* Submission Code */}
                                <div className="padded">
                                    <TextField 
                                        required
                                        id="inputCode" 
                                        label="Submission Code"
                                        variant="outlined"
                                        helperText="Ask an admin for this form's submission code."
                                        error={error}
                                        onChange={updateFormControl}
                                        value={formState.inputCode} 
                                        InputProps={codeAdornment}
                                        onFocus={e => setIsSelected(true)}
                                        onBlur={formState.inputCode ? undefined : e => setIsSelected(false)}
                                        onInput={e => setIsSelected(true)}
                                    />
                                </div>

                                {/* Form UUID */}
                                <div className="padded">
                                    {screenSize.small && (
                                        <FormHelperText>
                                            Entry ID:
                                        </FormHelperText> 
                                    )}
                                    <FormHelperText>
                                        {!screenSize.small && <>Entry ID:&nbsp;</>}
                                        <span className="font-bg1">{formState.SubmissionUuid}</span>&nbsp;
                                        <IoReloadSharp className="uuid-reload" onClick={newUUID} />
                                    </FormHelperText> 
                                </div>
                            </CardContent>
                        </form>
                    </Card>
                </Box>
            </div>
            <br />
            <button 
                className="main-button modal" 
                disabled={submitting}
                onClick={(e) => submitForm(e)}
            >
                {submitting ? 'Submitting...' : 'Submit'}
            </button>
            <button className="outline-button modal" onClick={closeModalHandler}>Cancel</button>
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../assets/UnderConstruction.jpeg';
import './ComingSoon.scss';

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/coming-soon.jpeg'); 
        transition: background-image 1s ease-in-out; 
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function ComingSoon(props) {
    const { sideBarOpen } = props;
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    return (
        <section id="coming-soon-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Coming Soon</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="coming-soon-wrapper-div" className="coming-soon-wrapper-div">
                <div id="coming-soon-content" className="coming-soon-content">
                    <div>
                        <img src={logo} alt='logo.png' />
                    </div>
                    <h1>Coming Soon!</h1>
                    <p>Sorry, this page is still under construction.</p>
                    <p id="check-back">Check back soon for more updates!</p>
                </div>
            </div>
        </section>
    );
};

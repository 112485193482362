import React from 'react';
import { MdClose } from 'react-icons/md';
import { InstrumentEditForm } from '../Forms/InstrumentEditForm.tsx';
import { AccountEditForm } from '../Forms/AccountEditForm.tsx';
import './Modals.scss';
import './AccountModal.scss';

export function AccountModal(props) {
    // Get props and initalize state variables
    const { alert, account, closeHandler, type } = props;
    
    const handleClose = (action) => {
        closeHandler(action)
    };

    const alertHandler = (message) => {
        alert(message);
    };

    return (
        <>
            <div id="edit-instruments-modal" className="instruments-modal">
                <div className="edit-account-modal-content">
                    <div className="modal-close" onClick={handleClose}>
                        <MdClose />
                    </div>

                    {type === 'instrument-edit' && (
                        <InstrumentEditForm 
                            alert={alertHandler}
                            accInstrument={account.Instrument}
                            closeHandler={handleClose}
                            email={account.Email}
                        />
                    )}

                    {type === 'account-edit' && (
                        <AccountEditForm 
                            alert={alertHandler}
                            account={account}
                            closeHandler={handleClose}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
